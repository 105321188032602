import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Table, 
  Header, 
  Loader, 
  Dimmer, 
  Label,
  Grid,
  Form, 
  Button,
  Dropdown,
  List,
  Icon,
  Divider,
  Segment,
} from 'semantic-ui-react';
import styled from '@emotion/styled';
import ErrorBoundary from "react-error-boundary";
import { useStitchPufs } from "../StitchPufs";
import { useHistory } from "react-router-dom";
import { useStitchAuth } from "../StitchAuth";
import clientMap from '../clientMap.json';
import {
  LineChart, 
  Line, 
  ScatterChart,
  Scatter,
  XAxis, 
  YAxis, 
  ZAxis,
  LabelList,
  CartesianGrid, 
  Tooltip,
  ReferenceLine,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';

const RedFont = styled.span`
  color: #dc3545;
`
const GreenFont = styled.span`
  color: #20c997;
`
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

String.prototype.toProperCase = function () {
    return this.replace(/[^A-Za-z0-9 ]/g, '').replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

export default function Group(props){

  const { userCustomData, clientName } = useStitchAuth();
  const { groupResult, profile, actions } = useStitchPufs();
  const [ textQuery, setTextQuery ] = useState('');
  const [stateCode, setStateCode] = useState('CA');
  const [inProcess, setInProcess] = useState(true);

  useEffect(() => {

  }, [userCustomData])

  useEffect(()=>{
    if (userCustomData){
      if (!profile){
        actions.loadProfile(userCustomData.email);
      }
      if (profile){
        actions.findGroup(_.map(profile.selectedProviders, v=>v.npi)).then(()=>{
          setInProcess(false);
        })
      }
    }
  }, [userCustomData, profile]);

  const searchTextQuery = () => {
    actions.searchByName(textQuery, stateCode);
  }

  return <div>
    <Dimmer active={inProcess} inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer>
    {profile && 
    <Grid>
      <Grid.Row textAlign='center'>
        <Grid.Column>
        <List horizontal size='mini'>
          {_.map(profile.selectedProviders, (doctor) => 
            <List.Item key={doctor.npi} style={{minWidth: '160px', marginLeft: 0}}>
              <Segment style={{padding: '0.5em', margin: '0.2em'}}>
              <List.Content floated='left' style={{lineHeight: 1.2}}>
                {doctor.name.toProperCase()}<br/>
                <small>{doctor.npi}</small>       
              </List.Content>
              <Button basic size='mini' color='red' icon onClick={()=>{
                setInProcess(true);
                actions.saveProfile(userCustomData.email, 
                    {...profile, 
                      selectedProviders: _.filter(profile.selectedProviders, x => x.npi !== doctor.npi)})
                  .then(()=>setInProcess(false));
              }}>
                <Icon name="remove"/>
              </Button> 
              </Segment>
            </List.Item>
          )}
        </List>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    }
    <Divider hidden/>
    {(groupResult && groupResult.group) && 
    <Grid style={{paddingRight: '2em'}}>
      <Grid.Row textAlign="center">
          <Grid.Column>
            <Header as='h2'>
              Group Statistics
              <Header.Subheader>
              Aggregate Statistics of All Group Members
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
      <Grid.Row textAlign='center' columns='three'>
        <Grid.Column>
          <Header as='h1' style={{lineHeight: '1.7em'}}>
            {numberWithCommas(groupResult.group.total_unique_benes[groupResult.group.refYear])}
            &nbsp;
              <span style={{fontSize: '0.6em'}}>
                {(groupResult.group.total_unique_benes.yoy > 1)?
                  (<GreenFont>+{Math.round(groupResult.group.total_unique_benes.yoy*100-100)}%</GreenFont>):
                  (<RedFont>{Math.round(groupResult.group.total_unique_benes.yoy*100-100)}%</RedFont>)}
                &nbsp;YoY
              </span>
            <Header.Subheader>
              # of Unique Beneficiaries <br/>
              <span  style={{fontSize: '0.7em', lineHeight: '1.2em'}}>
                {groupResult.state && <span>{groupResult.state.name + " (YoY): "}</span>}
                {(groupResult.state && groupResult.state.total_unique_benes.yoy > 1) &&
                  <GreenFont>+{Math.round(groupResult.state.total_unique_benes.yoy*100-100)}%</GreenFont>}
                {(groupResult.state && groupResult.state.total_unique_benes.yoy <= 1) &&
                  <RedFont>{Math.round(groupResult.state.total_unique_benes.yoy*100-100)}%</RedFont>}                  
                <br/>
                {groupResult.nation && <span>{groupResult.nation.name + " (YoY): "}</span>}
                {(groupResult.nation && groupResult.nation.total_unique_benes.yoy > 1) &&
                  <GreenFont>+{Math.round(groupResult.nation.total_unique_benes.yoy*100-100)}%</GreenFont>}
                {(groupResult.nation && groupResult.nation.total_unique_benes.yoy <= 1) &&
                  <RedFont>{Math.round(groupResult.nation.total_unique_benes.yoy*100-100)}%</RedFont>}   
              </span>
            </Header.Subheader>
          </Header>  
        </Grid.Column>
        <Grid.Column>
          <Header as='h1' style={{lineHeight: '1.7em'}}>
            {groupResult.group.beneficiary_average_risk_score[groupResult.group.refYear]}
            &nbsp;
              <span style={{fontSize: '0.6em'}}>
                {(groupResult.group.beneficiary_average_risk_score.yoy > 1)?
                  (<GreenFont>+{Math.round(groupResult.group.beneficiary_average_risk_score.yoy*100-100)}%</GreenFont>):
                  (<RedFont>{Math.round(groupResult.group.beneficiary_average_risk_score.yoy*100-100)}%</RedFont>)}
                &nbsp;YoY
              </span>
            <Header.Subheader>
              Avg. HCC Risk Score<br/>
              <span  style={{fontSize: '0.7em', lineHeight: '1.2em'}}>
                {groupResult.state && <span>{groupResult.state.name + " (YoY): "}</span>}
                {(groupResult.state && groupResult.state.beneficiary_average_risk_score.yoy > 1) &&
                  <GreenFont>+{Math.round(groupResult.state.beneficiary_average_risk_score.yoy*100-100)}%</GreenFont>}
                {(groupResult.state && groupResult.state.beneficiary_average_risk_score.yoy <= 1) &&
                  <RedFont>{Math.round(groupResult.state.beneficiary_average_risk_score.yoy*100-100)}%</RedFont>}                  
                <br/>
                {groupResult.nation && <span>{groupResult.nation.name + " (YoY): "}</span>}
                {(groupResult.nation && groupResult.nation.beneficiary_average_risk_score.yoy > 1) &&
                  <GreenFont>+{Math.round(groupResult.nation.beneficiary_average_risk_score.yoy*100-100)}%</GreenFont>}
                {(groupResult.nation && groupResult.nation.beneficiary_average_risk_score.yoy <= 1) &&
                  <RedFont>{Math.round(groupResult.nation.beneficiary_average_risk_score.yoy*100-100)}%</RedFont>}   
              </span>
            </Header.Subheader>
          </Header>  
        </Grid.Column>
        <Grid.Column>
          <Header as='h1' style={{lineHeight: '1.7em'}}>
            ${numberWithCommas(Math.round(groupResult.group.allowed_amt_per_bene[groupResult.group.refYear]*100)/100)}
            &nbsp;
              <span style={{fontSize: '0.6em'}}>
                {(groupResult.group.allowed_amt_per_bene.yoy > 1)?
                  (<GreenFont>+{Math.round(groupResult.group.allowed_amt_per_bene.yoy*100-100)}%</GreenFont>):
                  (<RedFont>{Math.round(groupResult.group.allowed_amt_per_bene.yoy*100-100)}%</RedFont>)}
                &nbsp;YoY
              </span>
            <Header.Subheader>
              Medicare Allowed Amounts<br/>per Beneficiary (USD)
            </Header.Subheader>
          </Header>  
        </Grid.Column>
      </Grid.Row>
      <Grid.Row textAlign="center" columns="three">
        {groupResult.group && 
          <Grid.Column>
            <Header as='h4'style={{fontWeight: 300, color: '#869AB8'}}>
              # of Unique Beneficiaries vs. Years
            </Header>
           <ResponsiveContainer width='100%' height={250}>
            <LineChart
              data={groupResult.group.plotBenes}
              margin={{
                top: 0, right: 10, left: 10, bottom: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey='year'/>
              <YAxis type='number'
                domain={[dataMin => Math.round(Math.max(dataMin-10,0)), dataMax => Math.round(dataMax+10)]}
              />
              <Line type="linear" dataKey="value" stroke="#3c72ca" strokeWidth={2} 
                dot={{ strokeWidth: 1 }} />
              <Tooltip/>
            </LineChart>
          </ResponsiveContainer>
        </Grid.Column>}
        {groupResult.group && 
          <Grid.Column>
            <Header as='h4'style={{fontWeight: 300, color: '#869AB8'}}>
              Avg. HCC Risk Scores vs. Years
            </Header>
           <ResponsiveContainer width='100%' height={250}>
            <LineChart
              data={groupResult.group.plotRisk}
              margin={{
                top: 0, right: 10, left: 10, bottom: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey='year'/>
              <YAxis type='number'
                allowDecimals={true}
                domain={[dataMin => Math.round((dataMin-0.1)*1000)/1000, dataMax => Math.round((dataMax+0.1)*1000)/1000]}
              />
              <Line type="linear" dataKey="value" stroke="#3c72ca" strokeWidth={2} 
                dot={{ strokeWidth: 1 }} />
              <Tooltip/>
            </LineChart>
          </ResponsiveContainer>
        </Grid.Column>}
        {groupResult.group && 
          <Grid.Column>
            <Header as='h4'style={{fontWeight: 300, color: '#869AB8'}}>
              Medicare Allowed Amt./Bene. vs. Years
            </Header>
           <ResponsiveContainer width='100%' height={250}>
            <LineChart
              data={groupResult.group.plotAmount}
              margin={{
                top: 0, right: 10, left: 10, bottom: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey='year'/>
              <YAxis type='number'
                allowDecimals={true}
                domain={[dataMin => Math.max(Math.round(dataMin - 10),0), dataMax => Math.round(dataMax + 10)]}
              />
              <Line type="linear" dataKey="value" stroke="#3c72ca" strokeWidth={2} 
                dot={{ strokeWidth: 1 }} />
              <Tooltip/>
            </LineChart>
          </ResponsiveContainer>
        </Grid.Column>}
      </Grid.Row>
      <Grid.Row textAlign="center" columns="two">
          {groupResult.group && 
            <Grid.Column>
              <Header as='h4' style={{fontWeight: 300, color: '#869AB8'}}>
                Gender Distributions vs. Years
              </Header>
              <ResponsiveContainer width='100%' height={250}>
                <BarChart
                  data={groupResult.group.plotGender}
                  margin={{
                    top: 0, right: 10, left: 10, bottom: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey='year'/>
                  <YAxis/>
                  <Tooltip/>
                  <Bar dataKey="male" stackId="a" fill="#3c72ca" />
                  <Bar dataKey="female" stackId="a" fill="#a8bdce" />   
                  <Legend layout='vertical' verticalAlign="middle" align="right" wrapperStyle={{
                      paddingLeft: "10px"
                  }}/>               
                </BarChart>
              </ResponsiveContainer>
            </Grid.Column>
          }
          {groupResult.group && 
            <Grid.Column>
              <Header as='h4' style={{fontWeight: 300, color: '#869AB8'}}>
                Ethnicity Distributions vs. Years
              </Header>
              <ResponsiveContainer width='100%' height={250}>
                <BarChart
                  data={groupResult.group.plotRace}
                  margin={{
                    top: 0, right: 10, left: 10, bottom: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey='year'/>
                  <YAxis/>
                  <Tooltip/>
                  <Bar dataKey="white" stackId="a" fill="#3c72ca" />
                  <Bar dataKey="black" stackId="a" fill="#a8bdce" />
                  <Bar dataKey="asianPacific" stackId="a" fill="#20c997" />
                  <Bar dataKey="hispanic" stackId="a" fill="#6C8AEC" />
                  <Bar dataKey="americanIndian" stackId="a" fill="#7C69EF" />                                                         
                  <Bar dataKey="others" stackId="a" fill="#506690" />
                  <Legend layout='vertical' verticalAlign="middle" align="right" wrapperStyle={{
                      paddingLeft: "10px"
                  }}/>               
                </BarChart>
              </ResponsiveContainer>
            </Grid.Column>
          }
      </Grid.Row>
      <Grid.Row textAlign="center">
        {groupResult.group && 
        <Grid.Column>
          <Header as='h4' style={{fontWeight: 300, color: '#869AB8'}}>
            Percentages of Beneficiaries with Chronic Conditions in {groupResult.group.refYear}
          </Header>
          <ResponsiveContainer width='100%' height={700}>
            <BarChart
              data={groupResult.group.barChart}
              margin={{
                top: 0, right: 80, left: 120, bottom: 20,
              }}
              layout="vertical"
            >
              <XAxis type="number" unit="%"/>
              <YAxis dataKey="condition" type="category"/>
              <Tooltip />
              <Legend layout="vertical" verticalAlign="middle" align="right" />
              <Bar dataKey="Group" fill="#3c72ca" unit="%"/>
              <Bar dataKey="State" fill="#a8bdce" unit="%"/>
              <Bar dataKey="Nation" fill="#cbd7e1" unit="%"/>                                
            </BarChart>
          </ResponsiveContainer>
        </Grid.Column>}
      </Grid.Row>
      <Grid.Row textAlign="center">
        {groupResult.group && 
        <Grid.Column style={{paddingLeft: '10em', paddingRight: '10em'}}>
          <Header as='h4' style={{fontWeight: 300, color: '#869AB8'}}>
            Risk Score vs. Medicare Allowed Amounts per Beneficiary in {groupResult.group.refYear}
          </Header>
          <ResponsiveContainer width='100%' height={500}>
            <ScatterChart              
              margin={{
                top: 0, right: 10, left: 10, bottom: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" dataKey="amt" domain={
                [dataMin=>Math.round(dataMin-10),dataMax=>Math.round(dataMax+200)]} 
                height={60}
                label="Medicare Allowed Amounts per Beneficiary"/>
              <YAxis type="number" dataKey="raf" domain={
                [dataMin=>Math.round((dataMin-0.2)*10)/10,
                dataMax=>Math.round((dataMax+0.2)*10/10)]} 
                width={60}
                label={{value: "Average HCC Risk Score", angle: -90, position: 'insideLeft'}}
                />
              <ZAxis dataKey="numBenes" range={[50, 200]}/>
              <Tooltip />
              <Scatter data={groupResult.group.plotScatter} fill="#3c72ca" >
                <LabelList dataKey="name" position="right" />
              </Scatter>
            </ScatterChart>
          </ResponsiveContainer>
        </Grid.Column>}
      </Grid.Row>                   
    </Grid>
    }
    {!groupResult &&
      <Grid>
        <Grid.Row textAlign="center">
          <Grid.Column>
            <span style={{color: '#869AB8', fontWeight: 300}}>
              No group is defined.
            </span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    }
  </div>;

}