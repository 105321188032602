import { RemoteMongoClient } from "mongodb-stitch-browser-sdk";
import { app } from "./app";

// TODO: Initialize a MongoDB Service Client


const mongoClient2 = app.getServiceClient(
  RemoteMongoClient.factory,
  "mongodb-atlas-publicdata"
);
const summaryStats = mongoClient2.db('cmsProviderUtils').collection("summaryStats");
const profiles = mongoClient2.db('cmsProviderUtils').collection("profiles");

const mongoClient = app.getServiceClient(
  RemoteMongoClient.factory,
  "mongodb-atlas-test"
);
const DB_NAME = 'test';
const users = mongoClient.db(DB_NAME).collection("users");
const groups = mongoClient.db(DB_NAME).collection("groups");

export { summaryStats, profiles, users, groups };
