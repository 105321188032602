import React, { useState } from "react";
import AppBar from "../Common/AppBar";
import "../Common/Layout.css";
import { Header, Menu, Grid, } from 'semantic-ui-react'
import {
  useParams,
} from "react-router-dom";
import { useStitchAuth } from "../StitchAuth";

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

export default function MyData(props){

  const { clientName } = useStitchAuth();
  const { viewDefault } = useParams();
  const [ view, setView ] = useState(viewDefault || 'all-members');

  return (
      <div className='app-layout'>
        <AppBar />
        <div className='channels box'>
          <Header as='h4' style={{paddingLeft: '1em', color: '#fff'}}>
            Members
          </Header>
          <Menu vertical secondary inverted style={{background: 'transparent'}}>
            <Menu.Item active={view==='all-members'} onClick={()=>setView('all-members')}>
              #&nbsp;&nbsp;all-members
            </Menu.Item>
            <Menu.Item >
              #&nbsp;&nbsp;high-risk-members
            </Menu.Item>
            <Menu.Item >
              #&nbsp;&nbsp;dm-members
            </Menu.Item>
            <Menu.Item >
              #&nbsp;&nbsp;hptn-members
            </Menu.Item>
            <Menu.Item >
              #&nbsp;&nbsp;chf-members
            </Menu.Item>                                       
          </Menu>
        </div>
        <div className='header-panel box'>
          <Header as='h4'>
            Members
            <Header.Subheader>
              View your member data.
            </Header.Subheader>
          </Header>
        </div>
        <div className='messages box'>
          <Grid>
            <Grid.Row textAlign="center">
              <Grid.Column>
                <span style={{color: '#869AB8', fontWeight: 300}}>
                Available upon receipt of data.
                </span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
}
