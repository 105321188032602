import React, { useState, createContext } from "react";
import PropTypes from "prop-types";
import { summaryStats, profiles } from "./../stitch";
import _ from 'lodash';
import zip2fips from './data/zip2fips.json';

// Create a React Context that lets us expose and access auth state
// without passing props through many levels of the component tree
const StitchPufsContext = createContext();

// Create a React Hook that lets us get data from our auth context
export function useStitchPufs() {
  const context = React.useContext(StitchPufsContext);
  if (!context) {
    throw new Error(`useStitchPufs must be used within a StitchPufsProvider`);
  }
  return context;
}

export function StitchPufsProvider(props) {

  const [ searchResults, setSearchResults] = useState([]);
  const [ npiResult, setNpiResult] = useState();
  const [ groupResult, setGroupResult] = useState();
  const [ profile, setProfile ] = useState();
  const [ selectedProviders, setSelectedProviders ] = useState([]);
  const stateLst = ["MD","IL","OH","PA","CO","FL","OK","CA","TX","OR",
                  "MN","KY","SC","ME","MO","NY","CT","MI","GA","NJ",
                  "LA","TN","MA","WA","AR","NH","DC","NC","NV","HI",
                  "MS","IA","VA","ND","IN","NE","AZ","AL","PR","DE",
                  "KS","RI","WI","ID","NM","MT","WV","VT","AE","UT",
                  "WY","SD","AP","AK","VI","GU","ZZ","MP","AA","XX",
                  "AS"];

  const loadProfile = async (email) => {
    let res = await profiles.findOne({owner_id: email});
    if (!res){
      res = {};
    }
    if (!res.selectedProviders){
      res.selectedProviders = [];
    }
    setProfile(res);
  }

  const saveProfile = async (email, newProfile) => {
    await profiles.findOneAndUpdate({owner_id: email}, 
      {$set: {...profile, ...newProfile, owner_id: email}},
      {upsert: true});
    setProfile({...profile, ...newProfile});
  }

  const searchByName = async (textQuery, stateCode, resLimit=50) => {
    const query = {nppes_provider_state: stateCode, 
                $text: {$search: textQuery}};
    const res = await summaryStats.aggregate(
        [
          {$match: query},
          {$sort: { score: { $meta: "textScore" } } },
          {$limit: resLimit}
        ]).toArray();
    setSearchResults(res);
  }

  const findGroup = async (npiList) => {
    //const US = await summaryStats.findOne({npi: 'US'});
    const providers = await summaryStats.find({npi: {'$in': npiList}}).toArray();
    let refYear = 0;
    let stateCnt = {};
    let group = {};
    let noweightVars = ['total_unique_benes', 
                        'total_medicare_allowed_amt',
                        'beneficiary_male_count',
                        'beneficiary_female_count',
                        'beneficiary_race_white_count',
                        'beneficiary_race_black_count',
                        'beneficiary_race_api_count',
                        'beneficiary_race_hispanic_count',
                        'beneficiary_race_natind_count'
                        ];
    let weightedVars = ['beneficiary_average_risk_score', 
                    "beneficiary_cc_afib_percent",
                    "beneficiary_cc_asthma_percent",
                    "beneficiary_cc_cancer_percent",
                    "beneficiary_cc_chf_percent",
                    "beneficiary_cc_ckd_percent",
                    "beneficiary_cc_copd_percent",
                    "beneficiary_cc_depr_percent",
                    "beneficiary_cc_diab_percent",
                    "beneficiary_cc_hyperl_percent",
                    "beneficiary_cc_hypert_percent",
                    "beneficiary_cc_ihd_percent",
                    "beneficiary_cc_ost_percent",
                    "beneficiary_cc_raoa_percent",
                    "beneficiary_cc_schiot_percent",
                    "beneficiary_cc_strk_percent"];
    let barChart = {"beneficiary_cc_afib_percent": {"condition": "Atrial Fibrillation"},
                    "beneficiary_cc_asthma_percent": {"condition": "Asthma"},
                    "beneficiary_cc_cancer_percent": {"condition": "Cancer"},
                    "beneficiary_cc_chf_percent": {"condition": "Heart Failure"},
                    "beneficiary_cc_ckd_percent": {"condition": "Chronic Kidney Disease"},
                    "beneficiary_cc_copd_percent": {"condition": "COPD"},
                    "beneficiary_cc_depr_percent": {"condition": "Depression"},
                    "beneficiary_cc_diab_percent": {"condition": "Diabetes"},
                    "beneficiary_cc_hyperl_percent": {"condition": "Hyperlipidemia"},
                    "beneficiary_cc_hypert_percent": {"condition": "Hypertension"},
                    "beneficiary_cc_ihd_percent": {"condition": "Ischemic Heart Disease"},
                    "beneficiary_cc_ost_percent": {"condition": "Osteoporosis"},
                    "beneficiary_cc_raoa_percent": {"condition": "Arthritis"},
                    "beneficiary_cc_schiot_percent": {"condition": "Schizophrenia/Other Psychotic Disorders"},
                    "beneficiary_cc_strk_percent": {"condition": "Stroke"}} 

    if (providers.length > 0){


      _.forEach(providers, (doctor) => {
        
        _.forEach(noweightVars, k =>{
          if (!group[k]){
            group[k] = {};
          }
          _.forEach(doctor[k], (v, year)=>{
            refYear = (year > refYear)?year:refYear;
            group[k][year] = group[k][year]?(group[k][year]+v):v;
          });
        });


        _.forEach(weightedVars, k =>{
          if (!group[k]){
            group[k] = {};
          }
          _.forEach(doctor[k], (v, year)=>{
            group[k][year] = group[k][year]?(group[k][year]+v * doctor.total_unique_benes[year]):(v* doctor.total_unique_benes[year]);
          });
        });
      });

      // normalization
      _.forEach(weightedVars, k =>{
        _.forEach(group[k], (v, year) => {
          group[k][year] = Math.round(group[k][year]/group.total_unique_benes[year]*1000)/1000;
        })
      })

      _.forEach(providers, (doctor) =>{
        if (doctor.total_unique_benes[refYear]){
          stateCnt[doctor.nppes_provider_state] = (stateCnt[doctor.nppes_provider_state]?
                (stateCnt[doctor.nppes_provider_state] + doctor.total_unique_benes[refYear]):
                doctor.total_unique_benes[refYear]);
        }
      });

      const stateCode = _.maxBy(_.map(stateCnt, (v, k) => {return {name: k, value: v}}), 'name').name;
      let state = await summaryStats.findOne({npi: stateCode});
      let nation = await summaryStats.findOne({npi: 'US'});
      const lastYear = refYear - 1;

      group.refYear = refYear;
      group.plotBenes = _.map(group.total_unique_benes, (v, k) => {return {year: k, value: v}});
      group.plotRisk = _.map(group.beneficiary_average_risk_score, (v, k) => {return {year: k, value: v}});
      group.plotAmount = _.map(group.total_medicare_allowed_amt, (v, k) => {
                          return {year: k, value: Math.round(v/group.total_unique_benes[k])}});          
      group.plotGender = _.map(group.beneficiary_male_count, (v, k) => {
                          return {year: k, 
                                male: Math.round(v/group.total_unique_benes[k]*100)/100,
                                female: Math.round(group.beneficiary_female_count[k]/group.total_unique_benes[k]*100)/100};
                          });  
      group.plotRace = _.map(group.beneficiary_race_white_count, (v, k) => {
                          let item = {year: k, 
                                white: Math.round(v/group.total_unique_benes[k]*1000)/1000,
                                black: Math.round(group.beneficiary_race_black_count[k]/group.total_unique_benes[k]*1000)/1000,
                                asianPacific: Math.round(group.beneficiary_race_api_count[k]/group.total_unique_benes[k]*1000)/1000,
                                hispanic: Math.round(group.beneficiary_race_hispanic_count[k]/group.total_unique_benes[k]*1000)/1000,
                                americanIndian:Math.round(group.beneficiary_race_natind_count[k]/group.total_unique_benes[k]*1000)/1000};
                          item.others = 1 - item.white - item.black - item.asianPacific - item.hispanic - item.americanIndian;
                          item.others = Math.round(item.others * 1000)/1000;
                          return item;
                          });  
      group.plotScatter = _.map(providers, (doctor) => {return {
                            name: doctor.nppes_provider_last_org_name,
                            numBenes: doctor.total_unique_benes[refYear],
                            raf: doctor.beneficiary_average_risk_score[refYear],                            
                            amt: Math.round(doctor.total_medicare_allowed_amt[refYear]/doctor.total_unique_benes[refYear]*100)/100,
                          };});


      group.total_unique_benes.yoy = (group.total_unique_benes[refYear]/group.total_unique_benes[lastYear]);
      group.total_medicare_allowed_amt.yoy = (group.total_medicare_allowed_amt[refYear]/group.total_medicare_allowed_amt[lastYear]);
      group.beneficiary_average_risk_score.yoy = (group.beneficiary_average_risk_score[refYear]/group.beneficiary_average_risk_score[lastYear]);
      group.allowed_amt_per_bene = {
        [refYear]: Math.round(group.total_medicare_allowed_amt[refYear]/group.total_unique_benes[refYear]*100)/100,
        [lastYear]: Math.round(group.total_medicare_allowed_amt[lastYear]/group.total_unique_benes[lastYear]*100)/100
      }
      group.allowed_amt_per_bene.yoy = group.allowed_amt_per_bene[refYear]/group.allowed_amt_per_bene[lastYear];
      _.forEach(barChart, (v, k)=>{
        v['Group'] = group[k][refYear];
      });


      if (state){
        state.total_unique_benes.yoy = (state.total_unique_benes[refYear]/state.total_unique_benes[lastYear]);
        state.beneficiary_average_risk_score.yoy = (state.beneficiary_average_risk_score[refYear]/state.beneficiary_average_risk_score[lastYear]);
        _.forEach(barChart, (v, k) => {
          if (state[k]){
            v['State'] = state[k][refYear];
          }
        })
      
      }

      if (nation){
        nation.total_unique_benes.yoy = (nation.total_unique_benes[refYear]/nation.total_unique_benes[lastYear]);
        nation.beneficiary_average_risk_score.yoy = (nation.beneficiary_average_risk_score[refYear]/nation.beneficiary_average_risk_score[lastYear]);
        _.forEach(barChart, (v, k) => {
          if (nation[k]){
            v['Nation'] = nation[k][refYear];
          }
        })
      }
      group.barChart = _.map(barChart, v => v);
      setGroupResult({group: group, state: state, nation: nation});
    }else{
      setGroupResult(null);
    }
  }

  const findNpi = async (npi) => {
    //const US = await summaryStats.findOne({npi: 'US'});
    const doctor = await summaryStats.findOne({npi: npi});
    let state = null;
    let county = null;
    let barChart = {"beneficiary_cc_afib_percent": {"condition": "Atrial Fibrillation"},
                    "beneficiary_cc_asthma_percent": {"condition": "Asthma"},
                    "beneficiary_cc_cancer_percent": {"condition": "Cancer"},
                    "beneficiary_cc_chf_percent": {"condition": "Heart Failure"},
                    "beneficiary_cc_ckd_percent": {"condition": "Chronic Kidney Disease"},
                    "beneficiary_cc_copd_percent": {"condition": "COPD"},
                    "beneficiary_cc_depr_percent": {"condition": "Depression"},
                    "beneficiary_cc_diab_percent": {"condition": "Diabetes"},
                    "beneficiary_cc_hyperl_percent": {"condition": "Hyperlipidemia"},
                    "beneficiary_cc_hypert_percent": {"condition": "Hypertension"},
                    "beneficiary_cc_ihd_percent": {"condition": "Ischemic Heart Disease"},
                    "beneficiary_cc_ost_percent": {"condition": "Osteoporosis"},
                    "beneficiary_cc_raoa_percent": {"condition": "Arthritis"},
                    "beneficiary_cc_schiot_percent": {"condition": "Schizophrenia/Other Psychotic Disorders"},
                    "beneficiary_cc_strk_percent": {"condition": "Stroke"}} 


    if (doctor){
      state = await summaryStats.findOne({npi: doctor.nppes_provider_state});
      const fips = zip2fips[doctor.nppes_provider_zip.substring(0,5)];
      if (fips){
        county = await summaryStats.findOne({npi: 'FIPS'+fips});
      }

      const refYear = _.max(Object.keys(doctor.total_unique_benes));
      const lastYear = '' + (refYear - 1);

      doctor.refYear = refYear;
      doctor.plotBenes = _.map(doctor.total_unique_benes, (v, k) => {return {year: k, value: v}});
      doctor.plotRisk = _.map(doctor.beneficiary_average_risk_score, (v, k) => {return {year: k, value: v}});
      doctor.plotAmount = _.map(doctor.total_medicare_allowed_amt, (v, k) => {
                          return {year: k, value: Math.round(v/doctor.total_unique_benes[k])}});    

      doctor.plotGender = _.map(doctor.beneficiary_male_count, (v, k) => {
                          return {year: k, 
                                male: Math.round(v/doctor.total_unique_benes[k]*100)/100,
                                female: Math.round(doctor.beneficiary_female_count[k]/doctor.total_unique_benes[k]*100)/100};
                          });  
      doctor.plotRace = _.map(doctor.beneficiary_race_white_count, (v, k) => {
                          let item = {year: k, 
                                white: Math.round(v/doctor.total_unique_benes[k]*1000)/1000,
                                black: Math.round(doctor.beneficiary_race_black_count[k]/doctor.total_unique_benes[k]*1000)/1000,
                                asianPacific: Math.round(doctor.beneficiary_race_api_count[k]/doctor.total_unique_benes[k]*1000)/1000,
                                hispanic: Math.round(doctor.beneficiary_race_hispanic_count[k]/doctor.total_unique_benes[k]*1000)/1000,
                                americanIndian:Math.round(doctor.beneficiary_race_natind_count[k]/doctor.total_unique_benes[k]*1000)/1000};
                          item.others = 1 - item.white - item.black - item.asianPacific - item.hispanic - item.americanIndian;
                          item.others = Math.round(item.others * 1000)/1000;
                          return item;
                          });  

      doctor.total_unique_benes.yoy = (doctor.total_unique_benes[refYear]/doctor.total_unique_benes[lastYear]);
      doctor.total_medicare_allowed_amt.yoy = (doctor.total_medicare_allowed_amt[refYear]/doctor.total_medicare_allowed_amt[lastYear]);
      doctor.allowed_amt_per_bene = {
        [refYear]: Math.round(doctor.total_medicare_allowed_amt[refYear]/doctor.total_unique_benes[refYear]*100)/100,
        [lastYear]: Math.round(doctor.total_medicare_allowed_amt[lastYear]/doctor.total_unique_benes[lastYear]*100)/100
      }
      doctor.allowed_amt_per_bene.yoy = doctor.allowed_amt_per_bene[refYear]/doctor.allowed_amt_per_bene[lastYear];

      doctor.beneficiary_average_risk_score.yoy = (doctor.beneficiary_average_risk_score[refYear]/doctor.beneficiary_average_risk_score[lastYear]);
      _.forEach(barChart, (v, k)=>{
        v['Provider'] = doctor[k][refYear];
        //v["YoY"] = Math.round(doctor[k][refYear]/doctor[k][lastYear]*100-100)
      });


      if (county){
        county.total_unique_benes.yoy = (county.total_unique_benes[refYear]/county.total_unique_benes[lastYear]);
        county.beneficiary_average_risk_score.yoy = (county.beneficiary_average_risk_score[refYear]/county.beneficiary_average_risk_score[lastYear]);
        _.forEach(barChart, (v, k) => {
          if (county[k]){
            v['County'] = county[k][refYear];
          }
        })
      
      }

      if (state){
        state.total_unique_benes.yoy = (state.total_unique_benes[refYear]/state.total_unique_benes[lastYear]);
        state.beneficiary_average_risk_score.yoy = (state.beneficiary_average_risk_score[refYear]/state.beneficiary_average_risk_score[lastYear]);
        _.forEach(barChart, (v, k) => {
          if (state[k]){
            v['State'] = state[k][refYear];
          }
        })
      }
      doctor.barChart = _.map(barChart, v => v);

    }

    setNpiResult({doctor: doctor, county: county, state: state});
  }

  // We useMemo to improve performance by eliminating some re-renders
  const pufsInfo = React.useMemo(
    () => {
      const value = {
        searchResults,
        npiResult,
        groupResult,
        selectedProviders,
        profile,
        actions: {
          loadProfile,
          saveProfile,
          searchByName,
          findNpi,
          findGroup,
        },
      };
      return value;
    },
    [selectedProviders, searchResults, npiResult, groupResult, profile, ],
  );
  return (
    <StitchPufsContext.Provider value={pufsInfo}>
      {props.children}
    </StitchPufsContext.Provider>
  );

}
StitchPufsProvider.propTypes = {
  children: PropTypes.element,
};