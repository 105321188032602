import React, { useEffect } from "react";
import "./Common/Layout.css";
import { useStitchAuth } from "./StitchAuth";
import Agreement from "./Agreement/Agreement";
import Pufs from "./Pufs/Pufs";

export default function Home(){

  const { userCustomData, actions} = useStitchAuth();

  useEffect(() => {
    if (!userCustomData){
      actions.handleRefreshCustomData();
    }
  }, []);


  if (userCustomData){

    if (!userCustomData.customer_agreement_consent){
      return <Agreement/>
    }else{
      return <Pufs/>
    }
  }else{
    return <div></div>;
  }


}
