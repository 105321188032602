import React, { useState, useEffect } from "react";
import "../Common/Layout.css";
import TermsAndConditions from './TermsAndConditions';
import { Grid, Form, Modal, Header, Message, Dimmer, Loader, } from 'semantic-ui-react'
import { useStitchAuth } from '../StitchAuth';
import { 
  useHistory
} from "react-router-dom";
import moment from 'moment-timezone';

export default function AgreementForm(props){

  let history = useHistory();

  const { userCustomData, actions } = useStitchAuth();

  const [ formError, setFormError ] = useState(false);
  const [ confirm, setConfirm ] = useState(false);
  const [ businessName, setBusinessName ] = useState("");
  const [ contactName, setContactName ] = useState("");
  const [ address, setAddress ] = useState("");
  const [ phoneNumber, setPhoneNumber ] = useState("");
  const [ fromDate, setFromDate ] = useState("");
  const [ toDate, setToDate ] = useState("");
  const [ success, setSuccess ] = useState(false);
  const [ openTC, setOpenTC ] = useState(false);

  useEffect(() => {
    if (userCustomData){
      if (userCustomData.agreement){
        setBusinessName(userCustomData.agreement.businessName || '');
        setContactName(userCustomData.agreement.contactName || '');
        setAddress(userCustomData.agreement.address || '');
        setPhoneNumber(userCustomData.agreement.phoneNumber || '');
        setFromDate(userCustomData.agreement.fromDate);
        setToDate(userCustomData.agreement.toDate);
        setConfirm(userCustomData.termsAndConditions==='agreed');
      }else{
        setFromDate(moment(new Date()).format('MM/DD/YYYY'));
        setToDate(moment(new Date()).add(1, 'year').subtract(1, 'days').format('MM/DD/YYYY'));        
      }

    }
  }, [userCustomData]);

  const handleToDateChange = (event) => {
    const {value} = event.target;
    let newValue = '';
    if (toDate.length > value.length){ // delete operation
      setToDate(value);
    } else if(!isNaN(value[value.length-1])) {
      newValue = value.substring(0,2);
      if (newValue.length === 2) { newValue += '/'; }
      if (value.length > 3){ newValue += value.substring(3,5); }
      if (newValue.length === 5) { newValue += '/'; }
      if (value.length > 6){ newValue += value.substring(6,10); }
      setToDate(newValue);
    }
  }

  const handleFromDateChange = (event) => {
    const {value} = event.target;
    let newValue = '';
    if (fromDate.length > value.length){ // delete operation
      setFromDate(value)
    } else if(!isNaN(value[value.length-1])) {
      newValue = value.substring(0,2);
      if (newValue.length === 2) { newValue += '/'; }
      if (value.length > 3){ newValue += value.substring(3,5); }
      if (newValue.length === 5) { newValue += '/'; }
      if (value.length > 6){ newValue += value.substring(6,10); }
      setFromDate(newValue)
    }
  }

  const handlePhoneNumberChange = (event) => {
    const {value} = event.target;
    const numOnly = value.replace(/(\D+)/g, "");
    let phoneFormat = numOnly.slice(0,3);
    if (numOnly.length > 3){ 
      phoneFormat = phoneFormat + '-' + numOnly.slice(3,6);
    }
    if (numOnly.length > 6){
      phoneFormat = phoneFormat + '-' + numOnly.slice(6, 10);
    }
    setPhoneNumber(phoneFormat);
  }

  const handleSubmit = () => {

    const fromTimeStamp = Date.parse(fromDate);
    const toTimeStamp = Date.parse(toDate)

    if (businessName==='' ||
      isNaN(fromTimeStamp) ||
      isNaN(toTimeStamp) ||   
      contactName === ''  ||  
      phoneNumber==='' ||
      address===''){
      setFormError(true);
    }else{
      setSuccess(true);
      actions.updateAgreement({
        agreement: {
          businessName: businessName,
          contactName: contactName,
          fromDate: fromDate,
          toDate: toDate,
          address: address,
          phoneNumber: phoneNumber
        },
        termsAndConditions: 'agreed',
        customer_agreement_consent: true,
        signedDate: new Date().toISOString()
      });
    }
  };

  if(!userCustomData){
    return(
      <div className='messages box'>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    ) 
  }else{

  return (
        <div className='messages'>
          <Grid columns={1} style={{margin: 0, paddingBottom: '5em', paddingLeft: '2em'}}>
            <Grid.Column width={12} textAlign='left'>
              {props.disableInput && 
              <Message info>
                <Message.Header>You have READ-ONLY access.</Message.Header>
                <p>If you have signed a separate agreement with Orma Health, please ignore this section.</p>
              </Message>}
            </Grid.Column>
            <Grid.Column width={12} textAlign='left' verticalAlign='middle'>
              <Form error={formError} >
                <Form.Input fluid 
                  required
                  readOnly={props.disableInput}
                  name='businessName' label='Business name' placeholder='Business name' 
                  value={businessName} 
                  onChange={(e) => setBusinessName(e.target.value)} />
                <Form.Input fluid 
                  required
                  readOnly={props.disableInput}
                  name='contactName' label='Contact name & position' placeholder='Contact name & position' 
                  value={contactName} 
                  onChange={(e) => setContactName(e.target.value)} />
                <Form.Input fluid 
                  required
                  readOnly={props.disableInput}
                  name='address' label='Customer address' placeholder='0000 Street address, City, State ZIP' 
                  value={address} 
                  onChange={(e) => setAddress(e.target.value)} />
                <Form.Input fluid 
                  required
                  readOnly={props.disableInput}
                  name='phone' label='Phone number' placeholder='000-000-0000' 
                  value={phoneNumber} 
                  onChange={handlePhoneNumberChange} />  
                <Header as='h4'>
                  SERVICES
                </Header>
                <p>
                  Customer agrees to purchase the Orma Health software platform and associated devices from Orma Health, Inc. ("Orma Health"), 
                  according to the applicable Terms & Conditions.  
                  The Orma Health software platform provides an integrated patient-provider engagement platform that includes audiovisual,  
                  text message, and patient monitoring capabilities. 
                  The provider platform allows providers to track and monitor their patients' health data through connected peripheral devices 
                  (e.g., weight scale, blood pressure cuff). 
                </p>
                <Header as='h4'>
                  Service Terms
                  <Header.Subheader>
                  automatically renewed for twelve (12) month Service Terms, 
                  unless Customer or Orma Health gives notice within 30 days of the end of a Service Term.
                  </Header.Subheader>
                </Header>
                <Form.Group>
                  <Form.Input  
                    required
                    readOnly={props.disableInput}
                    name="fromDate" label='Service Start Date' 
                    placeholder='MM/DD/YYYY' 
                    value={fromDate} 
                    onChange={handleFromDateChange}/>
                  <Form.Input  
                    required
                    readOnly={props.disableInput}
                    name="toDate" label='Service End Date' 
                    placeholder='MM/DD/YYYY' 
                    value={toDate} 
                    onChange={handleToDateChange}/>
                </Form.Group>
                <Header as='h4'>
                  Pricing
                </Header>
                <p>
                  All payments are due to Orma Health within 30 days of receipt of invoice. 
                  Pricing is subject to change upon 30 days' written notice to Customer.
                </p>
                <ul>
                  <li>$35 per Participating Patient per month (weight scale or blood pressure cuff included)</li>
                  <li><b>Note:  There is no charge to the practice during the pilot period.</b> We will only begin billing if and when the provider begins billing.</li>
                </ul>
                <Header as='h4'>
                  Acknowledgement
                </Header>
                <p>
                  By clicking “Submit” below, you acknowledge that you have read, understand, 
                  and agree to purchase the items and services indicated above according to 
                  the applicable Terms and Conditions, including without limitation all warranty disclaimers, 
                  liability limitations, and use limitations. You also acknowledge and understand that doing 
                  so forms a legally binding contract between your organization and Orma Health, Inc., 
                  and you represent that you are legally authorized to enter into a legally binding contract 
                  on behalf of your organization.
                </p>
                <Form.Checkbox 
                readOnly={props.disableInput}
                label={<label>I agree to <a onClick={()=>setOpenTC(true)}>the Terms and Conditions</a>.</label>}
                checked={confirm}
                onChange={()=>{return confirm?setConfirm(false):setConfirm(true)}}/>
                <Message
                  error
                  header='Missing Information'
                  content='Please check if you missed filling out any form e.g. phone number, address, etc.'
                />
                <Form.Button primary disabled={!confirm} onClick={handleSubmit}>
                  Submit
                </Form.Button>
              </Form>
              <Modal open={openTC}
                onClose={()=>setOpenTC(false)}
                closeIcon>
                <Modal.Header>Terms and Conditions</Modal.Header>
                <Modal.Content>
                  <TermsAndConditions businessName={businessName} address={address}/>
                </Modal.Content>
              </Modal>
              <Modal open={success}
                onClose={()=>history.replace("/home")}
                basic
                closeIcon>
                <Modal.Header>Thanks for choosing Orma Health!</Modal.Header>
                <Modal.Content>
                  You can start enrolling your patients right away. <br/>
                  If you have any questions/issues using the platform, please email us at help@ormahealth.com
                </Modal.Content>
              </Modal>
            </Grid.Column>
          </Grid>
        </div>
    );
  }
}
