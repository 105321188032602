import clientMap from '../clientMap.json';
import _ from 'lodash';

export function getDefaultClientName(){
	let defaultClientName = "orma";
	const host = window.location.hostname;
	_.forEach(clientMap, (v, k) => {
		if (host.includes(v.emailDomain)){
			defaultClientName = k;
		}
	})
	return defaultClientName;
}

export function capitalize(s){
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}
