import React, { useState } from 'react'
import { Button, Form, Grid, Header, Image, Segment, Message } from 'semantic-ui-react'
import { useStitchAuth } from "./StitchAuth";
import { 
  useHistory,
  useParams,
} from "react-router-dom";
import clientMap from './clientMap.json';
import { getDefaultClientName, capitalize } from './Common/whitelabel';

const SignupForm = props => {
  
  const { client } = useParams();
  const defaultClientName = getDefaultClientName();
  const appName = (!client || client==="" || !clientMap[client])?defaultClientName:client;
  const logoFile = "";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState(false);
  
  const { actions } = useStitchAuth();

  const handleEmailChange = event => {
    setEmail(event.target.value.toLowerCase());
  }

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  }

  let history = useHistory();

  const handleSubmit = event => {
    
    actions.handleSignup(email, password)
      .then(
        () => { 
          //setFormSuccess(true); 
          //history.replace('/login');

          const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT;
          //fetch(endpoint + '/send-signup-email?email='+email);

          actions.handleEmailPasswordLogin(email, password)
            .then(
              () => {history.replace({pathname: '/home'});}
            )
            .catch(
              () => { setFormError(true); }
            );
          
        }
      )
      .catch(
        () => { setFormError(true); }
      );
  }

  return (
    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 500 }}>
        <Header as='h2' textAlign='center' style={{lineHeight: '1.5em'}}>
          <Image src={'/logos/'+appName+'_square.png'} size='medium' centered/>
          <Header.Content>Sign-up</Header.Content>
          <Header.Subheader style={{lineHeight: '1.5em', paddingTop: '0.5em'}}>
            Welcome to {capitalize(appName)} Health RPM Platform. <br/>
            To sign-up, we just need your email address and new password.<br/>
            After you sign-up, you can immediately start using the platform and receive the RPM reimbursements!
          </Header.Subheader>
        </Header>
        <Form size='large' success={formSuccess} error={formError} >
          <Segment style={{border: '0', boxShadow: '0 0 0 0'}}>
            <Message
              success
              header='Thanks for signing up!'
              content='You will receive an email to confirm your email address.'
            />
            <Message error>
              <Message.Content>
                <Message.Header>Sign-up Error</Message.Header>
                If you already have an account, please log-in <a href="/login">here</a>.
              </Message.Content>
            </Message>
            <Form.Input 
              fluid icon='user' 
              iconPosition='left' 
              placeholder='E-mail address'
              onChange={handleEmailChange}
            />
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='New password'
              type='password'
              onChange={handlePasswordChange}
            />      
            <Button 
              style={{backgroundColor: "#335eea", color: "#fff"}}
              primary
              fluid size='large' 
              onClick={handleSubmit}>
              Sign Up
            </Button>
            <p style={{ marginTop: '10px', color: '#869AB8', fontWeight: 300}}>
              Already signed up? Log-in <a href={'/login/'+client}>here</a>.
            </p>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
}

export default SignupForm;