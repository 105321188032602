import { 
  AnonymousCredential, 
  UserPasswordCredential,
  UserPasswordAuthProviderClient 
} from "mongodb-stitch-browser-sdk";
import { app } from "./app.js";
import { users, groups } from "./mongodb.js";

export function loginAnonymous() {
  // Allow users to log in anonymously
  const credential = new AnonymousCredential();
  return app.auth.loginWithCredential(credential);
}

export function hasLoggedInUser() {
  // Check if there is currently a logged in user
  return app.auth.isLoggedIn;
}

export function getCurrentUser() {
  // Return the user object of the currently logged in user
  return app.auth.isLoggedIn? app.auth.user : null;
}

export const getCustomData = async () => {
  if (app.auth.isLoggedIn){
    let customData = await users.findOne({id: app.auth.user.id});

    if (!customData){
      customData = {name: '', 
                  namePublic: '',
                  timeZone: 'America/Los_Angeles',
                  role: 'admin',
                  group_id: app.auth.user.profile.email,
                  id: app.auth.user.id,
                  email: app.auth.user.profile.email};
      users.insertOne(customData);
    }
    let groupData = await groups.findOne({group_id: customData.group_id});

    if (!groupData){
      groupData = {
        group_id: customData.group_id,
        customer_agreement_consent: false,
        termsAndConditions: 'no',
        text_usage_consent: false,
        auto_alert_text: false,
        auto_outreach_text: false,
        auto_reminder_text: false,
        clinical_rules: {
          highBPWarning: 'X',
          lowBPWarning: 'X',
          weightLossWarning: 'X',
          weightGainWarning: 'X',
          ecgWarning: [],
        }
      };
      groups.insertOne(groupData);
    }
    const data = {...groupData, ...customData};
    return data;
  }else{
    return null;
  }
}

export function logoutCurrentUser() {
  // Logout the currently logged in user
  const user = getCurrentUser();
  return app.auth.logoutUserWithId(user.id);
}

export function signupUser(email, password) {
  const emailPasswordClient = app.auth.getProviderClient(UserPasswordAuthProviderClient.factory);
  return emailPasswordClient.registerWithEmail(email, password);
}

export function confirmEmail(token, tokenId) {
  const emailPasswordClient = app.auth.getProviderClient(UserPasswordAuthProviderClient.factory);
  return emailPasswordClient.confirmUser(token, tokenId);
}

export function loginUser(email, password) {
  const credential = new UserPasswordCredential(email, password);
  return app.auth.loginWithCredential(credential);
}

export function sendResetPasswordEmail(email) {
  const emailPasswordClient = app.auth.getProviderClient(UserPasswordAuthProviderClient.factory);
  return emailPasswordClient.sendResetPasswordEmail(email);
}

export function resetPassword(token, tokenId, newPassword) {
  const emailPasswordClient = app.auth.getProviderClient(UserPasswordAuthProviderClient.factory);
  return emailPasswordClient.resetPassword(token, tokenId, newPassword);
}
