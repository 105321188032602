// React
import React from "react";

// Components & Hooks
import Agreement from "./Agreement/Agreement";
import Login from "./Login";
import Signup from "./Signup";
import ConfirmEmail from './ConfirmEmail';
import PasswordReset from "./PasswordReset";
import PasswordResetSend from "./PasswordResetSend";
import Pufs from "./Pufs/Pufs";
import Home from "./Home";
import Members from "./Members/Members";
import Performance from "./Performance/Performance";
import { StitchAuthProvider, useStitchAuth } from "./StitchAuth";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import 'semantic-ui-less/semantic.less'

window.YTConfig = {
  host: 'https://www.youtube.com' 
} 

App.propTypes = {};
export default function App() {

  return (
    <StitchAuthProvider>
      <Router>
        <Switch>
          <Route path='/confirmemail'>
            <ConfirmEmail />
          </Route>
          <Route path='/passwordreset'>
            <PasswordReset />
          </Route>
          <Route path='/passwordresetsend'>
            <PasswordResetSend />
          </Route>          
          <Route path='/signup/:client'>
            <Signup />
          </Route>
          <Route path='/signup'>
            <Signup />
          </Route>
          <Route path='/login/:client'>
            <Login />
          </Route>
          <Route path='/login'>
            <Login />
          </Route>
          <PrivateRoute path='/agreement'>
            <Agreement />
          </PrivateRoute>
          <PrivateRoute path='/members'>
            <Members />
          </PrivateRoute>
          <PrivateRoute path='/performance'>
            <Performance />
          </PrivateRoute>                             
          <PrivateRoute path='/pufs/:viewDefault'>
            <Home />
          </PrivateRoute>
          <PrivateRoute path='/pufs'>
            <Home />
          </PrivateRoute>
          <Route path="*">
            <Redirect to={{pathname: "/login"}}/>
          </Route>
        </Switch>
      </Router>
    </StitchAuthProvider>
  );
}


function PrivateRoute({ children, ...rest }) {

  const { isLoggedIn, clientName } = useStitchAuth();

  if (isLoggedIn){
    return <Route {...rest} render={() => children} />
  }else{
    return (
      <Route
        {...rest}
        render={({ location }) =>
           (
            <Redirect
              to={{
                pathname: "/login/"+clientName,
                state: { from: location }
              }}
            />
          )
        }
      />
    )
  }
}

