import React, { useState, useEffect } from "react";
import {
  Menu,
  Header,
  Divider,
} from "semantic-ui-react";
import { StitchPufsProvider } from "../StitchPufs";
import AppBar from "../Common/AppBar";
import "../Common/Layout.css";
import Search from "./Search";
import Find from "./Find";
import Group from "./Group";
import {
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
import { useStitchAuth } from "../StitchAuth";
import clientMap from '../clientMap.json';


function HeaderView(props){

  const viewMap = {'search-by-name': 'Search by Name',
                  'find-by-npi': 'Find by NPI',
                  'group-stats': 'Group Statistics',
                  }

  return (<div>
      <div className='header-panel box'>
        <Header as='h4'>
          {viewMap[props.view]}
          <Header.Subheader>
            <small>
            Sources: <a href="https://www.cms.gov/Research-Statistics-Data-and-Systems/Statistics-Trends-and-Reports/Medicare-Provider-Charge-Data/Physician-and-Other-Supplier2017">
                1. Physician and Other Supplier Data CY 2018, 2017, 2016, 2015, and 2014. 
              </a><br/>
              &nbsp;&nbsp;<a href="https://www.cms.gov/Research-Statistics-Data-and-Systems/Statistics-Trends-and-Reports/Medicare-Geographic-Variation/GV_PUF">
                2. Geographic Variation Public Use Files 
              </a><br/>
              &nbsp;&nbsp;<a href="https://www.cms.gov/Research-Statistics-Data-and-Systems/Statistics-Trends-and-Reports/Chronic-Conditions/CC_Main">
                3. Chronic Conditions Prevalence State/County Level
              </a> 
            </small>
          </Header.Subheader>
        </Header>
      </div>
    </div>); 
}

export default function Pufs(){

  const { viewDefault } = useParams();
  const { userCustomData, clientName } = useStitchAuth();
  const history = useHistory();
  const [view, setView] = useState(viewDefault || 'search-by-name');
  let location = useLocation();

  const handleViewChange = (newView) => {
    history.push('/pufs/'+newView);
    setView(newView);
  };

  useEffect(()=>{
    if(location.pathname.split('/')[2]){
      setView(location.pathname.split('/')[2]);
    }
  }, [location]);

  return (
    <StitchPufsProvider>
      <div className='app-layout'>
        <AppBar />
        <div className='channels box'>
          <Header as='h4' style={{paddingLeft: '1em', color: '#fff'}}>
            PUFs Explorer
          </Header>
          <Menu vertical secondary inverted style={{background: 'transparent'}}>
            <Menu.Item active={view==='search-by-name'} onClick={()=>handleViewChange('search-by-name')}>
              #&nbsp;&nbsp;search-by-name
            </Menu.Item>
            <Menu.Item active={view==='find-by-npi'} onClick={()=>handleViewChange('find-by-npi')}>
              #&nbsp;&nbsp;find-by-npi
            </Menu.Item>
            <Menu.Item active={view==='group-stats'} onClick={()=>handleViewChange('group-stats')}>
              #&nbsp;&nbsp;group-stats
            </Menu.Item>                    
          </Menu>
        </div>
        <HeaderView view={view}/>
        <div className='messages box' style={{padding: '1em', 'paddingBottom': '5em'}}>
          {view==='search-by-name' && <Search/>}
          {view==='find-by-npi' && <Find/>}         
          {view==='group-stats' && <Group/>} 
        </div>
      </div>
    </StitchPufsProvider>
    );
}
