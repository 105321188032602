import React, { useState } from 'react'
import { 
  Button, 
  Form, 
  Grid, 
  Header, 
  Image, 
  Segment, 
  Message, 
  Dimmer, 
  Loader, } from 'semantic-ui-react'
import { useStitchAuth } from "./StitchAuth";
import { 
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import clientMap from './clientMap.json';
import { getDefaultClientName, capitalize } from './Common/whitelabel';

const LoginForm = (props) => {
  
  const { client } = useParams();
  let location = useLocation();
  const defaultClientName = getDefaultClientName();
  const appName = (!client || client==="" || !clientMap[client])?defaultClientName:client;
  const logoFile = "";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState(false);
  const [inProcess, setInProcess] = useState(false);
  const { actions } = useStitchAuth();

  const handleEmailChange = event => {
    setEmail(event.target.value.toLowerCase());
  }

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  }

  let history = useHistory();

  const handleSubmit = event => {

    setInProcess(true);
    actions.handleEmailPasswordLogin(email, password)
      .then(
        () => {
          let pathname = '/pufs/search-by-name';
          if(location.state &&
            location.state.from && 
            location.state.from.pathname){
            pathname = location.state.from.pathname
          }          
          history.replace({pathname: pathname});
        }
      )
      .catch(
        () => { setInProcess(false); setFormError(true);  }
      );
  }

  return (
    <div style={{
        backgroundImage: 'url("/images/shutterstock_1846253494.jpg")',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}>
    <Dimmer inverted active={inProcess}>
      <Loader/>
    </Dimmer>
    <Grid textAlign='center' style={{
          height: '100vh',
          margin: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
        }} verticalAlign='middle'>

      <Grid.Column style={{
            maxWidth: 450,
            backgroundColor: '#fff',
            padding: '3em',
          }}>
        <Header as='h2' icon textAlign='center' style={{marginBottom: '1.5em'}}>
          <Image src={'/logos/orma_square.png'} style={{
                margin: '0.7em',
                height: '4em',
                width: 'auto',
              }}/>
          <Header.Content>Risk Contract Explorer
          <Header.Subheader>Log-in to your account</Header.Subheader>
          </Header.Content>
        </Header>
              
        <Form size='large' error={formError}>
          <Segment style={{border: '0', boxShadow: '0 0 0 0'}}>
            <Message
              error
              header='Invalid Username or Password'
              content='Please check your username/password again.'
            />
            <Form.Input 
              fluid icon='user' 
              iconPosition='left' 
              placeholder='E-mail address'
              onChange={handleEmailChange}
            />
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              type='password'
              onChange={handlePasswordChange}
            />            
            <Button 
              style={{backgroundColor: "#335eea", color: "#fff"}}
              fluid size='large' 
              onClick={handleSubmit}>
              Log-in
            </Button>
          </Segment>
        </Form>
        <p style={{ marginTop: '2em', color: '#869AB8', fontWeight: 300}}>
          Forgot your password? <a href='/passwordresetsend'>Reset your password.</a>
        </p>
        <p style={{color: '#869AB8', fontWeight: 300}}>
          If you don't have an account yet, you can <a href={'/signup/'+client}>sign up</a> right away.
        </p>
        
      </Grid.Column>
    </Grid>
    </div>
  );
}

export default LoginForm;