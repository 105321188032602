import React from 'react'

export default function TermsAndConditions(props) {

  return (
  	<div style={{fontSize: '0.7em', fontWeight: 300}}>
This Agreement (the "Agreement") is made as of {new Date().toLocaleDateString()} ("Effective Date") between Orma Health, Inc., 
with principal offices at 1117 S. California Avenue, Palo Alto, CA 94304 ("Orma Health") and {props.businessName} at {props.address} ("Customer"). 
Orma Health and Customer are collectively referred to herein individually as "Party" and collectively as the "Parties."<br/>
<br/>
<b>Background</b>
<ul>
  <li>Orma Health provides an integrated patient-provider engagement platform that includes audiovisual, text message, and patient monitoring capabilities, allowing providers to track and monitor their patients’ health data through connected peripheral devices (e.g., weight scale, blood pressure cuff).</li>
  <li>Customer is a healthcare provider that provides services to patients that may benefit from Orma Health.</li>
  <li>The Parties desire to enter into an agreement whereby Orma Health provides the Services to Customer and Participating Patients.</li>
</ul>

The Parties agree as follows:<br/><br/>

<b>1.  DEFINITIONS. </b>
The following definitions will apply to capitalized terms used throughout this Agreement.
<ol type='a'>
<li>"Authorized User" means (a) the employees, consultants, agents and subcontractors of Customer that Customer authorizes to access the Software on its behalf.</li>
<li>"Customer" means the organization indicated on the Order Form.</li>
<li>"Customer Data" means (a) all data and information Customer submits or transmits to Orma Health, excluding any PHI (as defined below) and/or Patient-Generated Health Data necessary for the Services; and (b) data, records and information Orma Health generates that relates directly to the Services for Customer under this Agreement, exclusive of information or documentation that Orma Health generates for use in Orma Health’s business generally or for use with multiple customers and exclusive of De-Identified Data as defined below.</li>
<li>"De-identified Data" means personally identifiable information (PII) and PHI (defined below) that has been stripped of certain identifiable elements in accordance with applicable law so as to render the individual’s data de-identified.</li>
<li>"Device" or "Devices" means the connected peripheral device(s) purchased from Orma Health for purposes of monitoring patient health data in conjunction with the Software.</li>
<li>"Helpdesk" means the customer support line operated by Orma Health to assist customers with troubleshooting and questions regarding use of the Software.</li>
<li>"Intellectual Property Rights" means any patent, invention, discovery, know-how, moral, technology, software, copyright, authorship, trade secret, trademark, trade dress, service mark, confidentiality, proprietary, privacy, intellectual property or similar rights (including rights in RPM applications, registrations, filings and renewals) that are now or hereafter protected or legally enforceable under state and/or Federal common laws or statutory laws or laws of foreign jurisdictions.</li>
<li>"Order Form" means the Orma Health Order Form provided to Customer for purposes of ordering Orma Health technology and services.</li>
<li>"Orma Health Data" means: (a) all data, software (in any form) and information Orma Health submits or transmits to Customer regarding Orma Health; (b) all data, records and information generated in Orma Health’s business or operations, including any information relating to Orma Health’s subcontractors and/or affiliates; (c) all Orma Health Intellectual Property (defined below), together with all derivative works of the Orma Health Intellectual Property; and (d) data, records or information occurring in any form, including written, graphic, electronic, visual or fixed in any tangible medium of expression and whether developed, generated, stored, possessed or used by Orma Health, Customer, or a third party if related to the items described in (a) through (c) above. Orma Health Data does not include any data or information that relates exclusively to Customer or Customer’s business, operations or activities.</li>
<li>"Participating Patients" means those patients of Customer’s practice that Customer has determined may benefit from RPM, that are enrolled in the Software, and that have agreed to the Terms of Use.</li>
<li>"Patient-Generated Health Data" or "PGHD" means physiologic data collected from a Participating Patient and transmitted through the Software.</li>
<li>"Patient Mobile App" means the mobile software application that receives Patient-Generated Health Data and relays it to Orma Health servers for storage/processing and displays a portion of the data collected to Participating Patients. </li>
<li>"Protected Health Information" or "PHI" shall have the meaning ascribed to such term in 45 C.F.R. 160.103.</li>
<li>"Provider-Facing Dashboard" means a web-based software platform that allows healthcare providers to view, track, and analyze their Participating Patients’ PGHD.</li>
<li>"Services" means the products and service offering(s) Customer purchases from Orma Health, including the Devices and Software.</li>
<li>"Software" means the Patient Mobile App, the Provider-Facing Dashboard, all of the capabilities and functionalities associated with the Patient Mobile App and Provider-Facing Dashboard, and user support services provided by Orma Health.</li>
<li>"Terms of Use" means the agreement between each of Customer’s individual users of the Software and Orma Health.</li>
</ol>

<b>2.  TERM. </b>
The Service Term indicated on the Order Form constitutes the Term of this Agreement. 
<br/><br/>
<b>3.  PRODUCTS & SERVICES. </b>
<ol type='a'>
<li><u>Software.</u> The Software provides a full featured remote patient monitoring and telehealth system consisting of the Patient Mobile App, the Provider-Facing Dashboard, the Devices, and data gathered from Participating Patients to enable healthcare organizations to monitor, document and manage patient health.</li>
  <ol type='i'>
    <li><u>License.</u> Subject to Customer’s compliance with this Agreement, Orma Health will provide a non-exclusive, limited license to access the Software to Customer and its Authorized Users. Customer and its Authorized Users may use the Software (a) to upload and/or transmit Customer Data by and through the Software; (b) to communicate and engage with Participating Patients; and (c) to access and use reports generated from time to time by Orma Health. </li>
  </ol>
<li><u>Devices.</u> Orma Health will provide the Devices to Participating Patients upon order from Customer. To be accepted, an order must include [insert necessary information to order devices].</li>
<li><u>Implementation & Training Services.</u> Orma Health will provide implementation and training services for Customer and Customer’s Authorized Users as well as initial Participating Patient education on use of the Software and relevant Devices. Implementation services include integrating Participating Patients’ information into the Software. Training services shall include virtual communication with Customer and its Authorized Users to educate them on use of the Software and related obligations and services.</li>
<li><u>Support Services.</u> Orma Health will provide technical support services to Customer (including Participating Patients, physicians, and staff) via telephone and/or email on weekdays between XXX am and XXX pm Pacific Time (PT), excluding Federal holidays ("Support Hours"). Customer may initiate a Helpdesk ticket during Support Hours by calling [insert phone number] or any time by emailing [insert email address]. Orma Health will use commercially reasonable efforts to respond to all Helpdesk tickets within one (1) business day, but Orma Health does not represent, warrant or guarantee that all tickets will be responded to within such time frame.</li>
</ol>

<b>4.  CUSTOMER RESPONSIBILITIES.</b>
<ol type='a'>
<li><u>Operations & Enrollment.</u> Customer is responsible for identifying and enrolling Participating Patients and ordering the Services for Participating Patients via the Software, fax, or email. Customer is responsible for providing sufficient information to Orma Health to allow Orma Health to furnish the Services to Participating Patients. Customer is also responsible for educating Participating Patients on appropriate use of the Software and Devices as related to their condition(s).</li>
<li><u>System.</u> Customer is responsible for (a) obtaining, deploying and maintaining all hardware, software, and equipment necessary for Customer and its Authorized Users to access and use the Services; (b) contracting with third party ISP, telecommunications and other service providers as necessary to access and use the Services; and (c) paying all third party fees and access charges incurred in connection with the foregoing.  Except as specifically set forth in this Agreement, Orma Health will not be responsible for supplying any hardware, software or other equipment to Customer under this Agreement and cannot be held responsible for any interruption in the Services that occurs as a result of any of the foregoing or Customer’s failure to maintain any of the foregoing.</li>
<li><u>Consents.</u> Unless otherwise agreed in writing, Customer is responsible for maintaining all necessary consents and authorizations to enable Orma Health to use, upload, process and store Customer Data and to provide the Services to Participating Patients, including without limitation consent to receive text messages regarding a Participating Patient’s care from Orma Health on behalf of Customer. Customer will not furnish any Customer Data that includes an individual’s PHI to Orma Health in the event such individual objects. Customer acknowledges and accepts full responsibility and liability for all Customer Data.</li>
<li><u>TOU.</u> Customer is responsible for its actions and the actions of its Authorized Users while using the Software. As a condition to Customer’s and its Authorized Users’ use of the Software, Customer shall require its Authorized Users to review and accept the Orma Health Terms of Use ("TOU"), as updated by Orma Health from time to time, prior to accessing the Software. Customer shall abide by and Customer shall ensure that its Authorized Users abide by the TOU when using or accessing the Software.</li>
<li><u>BAA.</u> Customer must enter into a Business Associate Agreement ("BAA") with Orma Health. The terms of the BAA between the Parties are incorporated into and made a part of this Agreement</li>
<li><u>Unenrollment of Patients.</u> Customer is responsible for determining when a Participating Patient is no longer eligible for or in need of the Services. Customer must unenroll the patient via the Software. If Customer fails to unenroll a patient after they are no longer in need of the Services, Orma Health may continue to provide Services to that Participating Patient and Customer will continue to be obligated to pay Orma Health for the Services.</li>
</ol>

<b>5.  PAYMENT & SHIPPING TERMS.</b>
<ol type='a'>
<li><u>Fees.</u> As compensation for the Services, Customer will pay Orma Health the Fees indicated on the Order Form. All amounts set forth in this Agreement are denominated and shall be paid in U.S. dollars.</li>
<li><u>Invoicing & Payment Method.</u> Orma Health will invoice Customer for fees owed to Orma Health on a monthly basis. Customer will pay Orma Health all undisputed amounts due via the payment method indicated on the Order Form. Customer can update or change their payment method by contacting Orma Health at [insert email or contact info].</li>
<li><u>Due Date.</u> Unless otherwise indicated on the Order Form and/or properly disputed according to Section 5(c)(i) below, Customer must pay all invoiced amounts to Orma Health within thirty (30) days of Customer’s receipt of an invoice.
  <ol type='i'>
    <li><u>Disputed Payments.</u> If Customer wishes to dispute any invoiced fees or expenses, Customer must notify Orma Health in writing within ten (10) business days of receipt of the invoice specifying such fees or expenses (a "Dispute Notice"). The Dispute Notice must specify the amounts that are being disputed as well as the reason for such dispute. Orma Health and Customer agree to attempt to resolve such dispute through informal meetings and discussions in good faith between appropriate representatives of the Parties within forty-five (45) days of receipt of the Dispute Notice before resorting to any other dispute resolution procedure.</li>
    <li><u>Overdue Payments.</u> Any undisputed amount not timely paid to Orma Health (an "Overdue Payment") may accrue, at Orma Health’s discretion, late charges at the rate of the lesser of one and one-half percent (1.5%) of the outstanding balance per month, or the maximum rate permitted by law, from the date such payment was due until the date paid. If there are undisputed payments outstanding for more than sixty (60) days from the due date, Orma Health reserves the right to suspend Authorized Users’ access to the Software until such amounts are paid in full. Customer will continue to be obligated to pay all Fees during any such suspension period.</li>
  </ol>
</li>
<li><u>Shipping.</u> Shipping terms are FOB [origin/destination] and Customer [is/is not] responsible for shipping costs.</li>
<li><u>Taxes.</u>  All amounts payable to Orma Health pursuant to this Agreement are exclusive of all local, state, federal and foreign taxes, levies, or duties of any nature ("Taxes"), and all payments to Orma Health are payable in full without reduction for Taxes. Customer is responsible for payment of all Taxes, excluding taxes owed by Orma Health based on Orma Health’s net income. If Orma Health is legally obligated to pay or collect Taxes for which Customer is responsible, the appropriate amount will be invoiced to and paid by Customer unless Customer provides Orma Health with a valid tax exemption certificate authorized by the appropriate taxing authority.</li>
</ol>

<b>6.  PROPRIETARY RIGHTS.</b>
<ol type='a'>
<li><u>Orma Health Intellectual Property.</u> As between Orma Health and Customer, all right, title and interest, including all Intellectual Property Rights, in the Software, Orma Health Data, and any other Orma Health property or materials furnished or made available as part of the Services, and all modifications and enhancements of the same ("Orma Health Intellectual Property"), belong to and are retained solely by Orma Health or Orma Health’s licensors and providers, as applicable. Nothing in this Agreement is intended to or may be construed to transfer any such rights in any part of the Services to Customer other than as explicitly provided for in this Agreement. Customer shall not re-distribute the Software or the Devices other than as specifically provided for in this Agreement.
  <ol type='i'>
    <li><u>Developments.</u> Except as otherwise explicitly set forth in this Agreement, all inventions, works of authorship, and developments conceived, created, written, or generated by or on behalf of Orma Health, whether solely or jointly, in connection with the Services ("Orma Health Developments") and all Intellectual Property Rights in the same, shall be the sole and exclusive property of Orma Health. Customer agrees to execute any documents or take any actions as may reasonably be necessary, or as Orma Health may reasonably request, to perfect Orma Health’s ownership of the Orma Health Developments. </li>
  </ol>
</li>
<li><u>Trademarks.</u>  Nothing in this Agreement shall grant either Party ownership interest, license or other right to the other Party’s trade names, trademarks or service marks, except as expressly provided in this Agreement.</li>
<li><u>Customer Data.</u>  As between Orma Health and Customer, all right, title and interest in the Customer Data belong to and are retained solely by Customer. 
  <ol type='i'>
    <li><u>Orma Health License.</u> Customer grants to Orma Health a limited, non-exclusive, royalty-free, worldwide license to (i) Use, reproduce, aggregate and modify the Customer Data and to perform all acts with respect to the Customer Data as may be necessary for Orma Health to provide the Services to Customer; (ii) Use or modify the Customer Data to create De-identified Data; and (iii) Use Customer’s name, logo, and trademark for marketing purposes upon written consent of Customer. Orma Health intends to use De-identified Data, aggregated with the de-identified data of other Orma Health customers, to enable Orma Health to provide more targeted, accurate, and useful insights to its customers. </li>
    <li><u>Accuracy of Customer Data.</u> As between Orma Health and Customer, Customer is solely responsible for the accuracy, quality, integrity, legality, reliability, and appropriateness of all Customer Data.  Customer Data will be included in and treated as Customer’s Confidential Information under this Agreement.</li>
  </ol>
</li>
<li><u>Feedback License.</u> Orma Health owns all right, title and interest in and to any suggestion, enhancement, request, recommendation, or other feedback related to the Software provided by Customer (any "Feedback").  Feedback is not Customer’s Confidential Information.</li>
<li><u>De-identified Data.</u> Orma Health may use, create, modify, aggregate, and disclose De-identified Data for any purposes not prohibited by law. Orma Health owns all rights, title and interest, and all Intellectual Property Rights in such De-identified Data and any data, information and material created by Orma Health with such De-identified Data. De-identified Data is NOT Customer Data. For the avoidance of doubt, the second and third sentences of this paragraph shall survive the expiration or earlier termination of this Agreement.</li>
</ol>

<b>7.  CONFIDENTIALITY. </b>
<ol type='a'>
<li><u>Confidential Information Defined.</u> "Confidential Information" means any and all non-public technical and non-technical information disclosed by one Party (the "Disclosing Party") to the other Party (the "Receiving Party") in any form or medium, that the Disclosing Party identifies as confidential or that by the nature of the circumstances surrounding the disclosure and/or receipt ought to be treated as confidential and proprietary information. Confidential Information includes, without limitation, (a) techniques, inventions (whether or not patented or patentable), know-how, processes, algorithms, software programs, software source and object codes and documents, APIs, and other creative works (whether or not copyrighted or copyrightable); (b) financial information, customer lists, business forecasts, and marketing plans and information; (c) the business relationships and affairs of either party and its clients, patients, and referral sources; (d) the internal policies and procedures of either Party; (e) proprietary or confidential information of any third party who may disclose such information to Disclosing Party or Receiving Party in the course of Disclosing Party’s business; and (f) the terms of this Agreement. Orma Health’s Confidential Information includes the Software and Orma Health Data.  Confidential Information of Customer includes Customer Data.  Confidential Information also includes all summaries and abstracts of Confidential Information. In addition, Confidential Information excludes PHI, which must be protected according to the BAA.
  <ol type='i'>
    <li><u>Exceptions.</u>  The term "Confidential Information" shall not include any information which, as evidenced by Receiving Party’s records:  (i) was known by the Receiving Party prior to receipt from the Disclosing Party either itself or through receipt directly or indirectly from a source with no obligation of confidentiality to the Disclosing Party; (ii) was developed by the Receiving Party without use of the Disclosing Party’s Confidential Information, or (iii) becomes publicly known or otherwise ceases to be secret or confidential, except as a result of a breach of this Agreement or any obligation of confidentiality by the Receiving Party.  </li>
  </ol>
</li>
<li><u>Confidential Information Terms.</u> The Receiving Party will, at all times, both during the term and thereafter, keep in confidence and trust all of the Disclosing Party’s Confidential Information.  The Receiving Party will not use the Disclosing Party’s Confidential Information other than as necessary to fulfill the Receiving Party’s obligations or to exercise the Receiving Party’s rights under this Agreement. Either Party may disclose the other Party’s Confidential Information upon the order of any competent court or government agency; provided that, prior to disclosure and to the extent possible, the receiving Party must (i) assert the confidential nature of the Confidential Information to the agency; (ii) immediately notify the Disclosing Party in writing of the order or request; and (iii) cooperate fully with the Disclosing Party in protecting against any such disclosure and/or narrowing the scope of the compelled disclosure. Each Party agrees to secure and protect the other Party’s Confidential Information with the same degree of care and in a manner consistent with the maintenance of such Party’s own Confidential Information (but in no event less than reasonable care).  The Receiving Party will not disclose Confidential Information of the Disclosing Party to any person or entity other than its officers, employees, affiliates and agents who need access to such Confidential Information in order to effect the intent of this Agreement and who are subject to confidentiality obligations at least as stringent as the obligations set forth in this Agreement.</li>
<li><u>Injunctive Relief.</u> The Parties agree that any unauthorized disclosure of Confidential Information may cause immediate and irreparable injury to the Disclosing Party and that, in the event of such breach, the Receiving Party will be entitled, in addition to any other available remedies, to seek immediate injunctive and other equitable relief, without bond and without the necessity of showing actual monetary damage.</li>
<li><u>HIPAA Compliance.</u>  Each Party, to the extent applicable, will comply with laws and regulations applicable to the privacy and security of individually identifiable health information, including but not limited to state laws and regulations and the Health Insurance Portability and Accountability Act ("HIPAA"), the Health Information Technology for Economic and Clinical Health Act ("HITECH"), and/or regulations promulgated thereunder ("HIPAA Regulations"). The BAA further describes the parties’ obligations with respect to compliance with HIPAA, HITECH, and HIPAA Regulations.</li>
<li><u>Security.</u> Each of Customer’s Authorized Users will create a unique user login and passwords to be used to access and use the Software. Customer will be, and will ensure that its Authorized Users are, responsible for maintaining the confidentiality of all Authorized User logins and passwords and for ensuring that each login and password is used only by the Authorized User to which it was issued.  Customer is responsible for ensuring that its Authorized Users do not share passwords with each other or any third party. Customer agrees to immediately notify Orma Health of any unauthorized use of any account or login and password issued to an Authorized User, or any other breach of security known to Customer. Orma Health will have no liability for any loss or damage arising from Customer’s failure to comply with the terms set forth in this Section. Customer will ensure its Authorized Users do not circumvent or otherwise interfere with any user authentication or security of the Software</li>
</ol>

<b>8.  TERMINATION. </b>
<ol type='a'>
<li><u>Without Cause.</u> Either Party may terminate this Agreement without cause upon ninety (90) days’ written notice to the other Party.</li>
<li><u>Mutual Agreement.</u> This Agreement shall terminate upon the mutual written agreement of Customer and Orma Health as of the date of signature or other effective date set forth on the written instrument.</li>
<li><u>For Cause.</u> 
  <ol type='i'>
    <li><u>Material Breach.</u> Either Party may terminate this Agreement following a material breach of this Agreement by the other Party which is not cured during the Cure Period. The non-breaching Party shall notify the breaching Party of the breach in writing and the breaching party shall have thirty (30) days (the "Cure Period") to cure the breach following receipt of the notification. If the breaching Party fails to cure the breach within the Cure Period, then the non-breaching Party may terminate this Agreement upon written notice to the breaching party.</li>
    <li><u>Other Cause.</u> Orma Health may terminate this Agreement immediately by providing written notice to Customer upon the occurrence of any of the following events:</li>
    <ol type='1'>
      <li>Orma Health reasonably determines that Customer and/or its Authorized User(s) have been or are engaged in unlawful activity associated with the use of the Software and/or the Services;</li>
      <li>The indictment or conviction of Customer or its principals, employees, or agents for any felony or misdemeanor involving moral turpitude;</li>
      <li>The filing, with respect to Customer, of a voluntary or involuntary petition in bankruptcy if such petition is not dismissed within thirty (30) days of such filing;</li>
      <li>Upon the appointment of a receiver or trustee to take possession of all, or substantially all, of Customer’s assets, if such appointment is not terminated within thirty (30) days; and/or</li>
      <li>For any other reason Orma Health feels could reasonably jeopardize the integrity or reputation of its operations or systems. </li>
    </ol>
  </ol>
</li>
<li><u>Effect of Termination.</u> Unless otherwise stated below, upon expiration or termination of this Agreement for any reason, (a) the License shall terminate and the Customer shall not use or access, directly or indirectly, the Software; (b) Orma Health’s obligation to perform support services shall cease; and (c) all fees and other amounts owed to Orma Health accrued prior to expiration or termination will be immediately due and payable. Further, if Customer has made any copies of any Orma Health property or materials furnished or made available under this Agreement, Customer shall, within thirty (30) days of the effective date of the expiration or termination, either destroy or return to Orma Health all such copies along with a certificate signed by Customer that all such copies have been either destroyed or returned, respectively, and that no copy or any part of the Software, data, or other materials has been retained by Customer in any form.
  <ol type='i'>
    <li><u>Return of Customer Data.</u> Within thirty (30) days after the effective date of applicable termination or expiration, Orma Health will make any Customer Data stored on the Software available upon written request to Customer. </li>
    <li><u>Prepaid Fees.</u> If Orma Health terminates this Agreement without cause pursuant to Section 8(a), or if Customer terminates this Agreement For Cause pursuant to Section 8(b) or 8(c), Customer shall be entitled to a refund of any fees pre-paid but unearned as of the effective date of termination.</li>
  </ol>
</li>
</ol>

<b>9.  REPRESENTATIONS & WARRANTIES. </b>
<ol type='a'>
<li><u>Mutual Representations and Warranties.</u>  Each Party represents, warrants and covenants that: (a) to its knowledge, it has the full power and authority to enter into this Agreement and to perform its obligations hereunder, without the need for any consents, approvals or immunities not yet obtained; (b) its acceptance of and performance under this Agreement will not breach any oral or written agreement with any third party or any obligation it owes to any third party; and (c) it will comply with any and all applicable local, state, and/or national laws or regulations applicable to such party, including, without limitation, those related to PHI, Covered Entities, and Business Associates as each term is defined under HIPAA, and to any other laws or regulations regarding data privacy and transmission of personal data.</li>
<li><u>Practice of Medicine.</u> CUSTOMER HEREBY AGREES AND ACKNOWLEDGES THAT Orma Health IS IN NO WAY ACTING AS A MEDICAL PROVIDER, NOR IS Orma Health PROVIDING 24/7 CONTINUOUS, SYNCHRONOUS, OR EMERGENCY MONITORING OR ALERTING. CUSTOMER FURTHER ACKNOWLEDGES AND AGREES THAT ANY INFORMATION, PROCESSES, PRODUCTS AND OTHER ITEMS REFERENCED BY Orma Health OR ITS SOFTWARE ARE NOT INTENDED AS A RECOMMENDATION OR ENDORSEMENT OF THAT INFORMATION, PROCESS, PRODUCT, OR OTHER ITEM AND THAT THE ULTIMATE RESPONSIBILITY FOR DIAGNOSING AND TREATING ANY PATIENT RESTS WITH CUSTOMER AND/OR ITS HEALTHCARE PROVIDER(S) TREATING SUCH PATIENT. </li>
<li><u>Third Party Materials.</u> CUSTOMER UNDERSTANDS AND AGREES THAT USING, ACCESSING, DOWNLOADING, OR OTHERWISE OBTAINING INFORMATION, MATERIALS, OR DATA THROUGH THE SOFTWARE FROM A SOURCE OTHER THAN Orma Health ("Third Party Materials") IS AT ITS OWN DISCRETION AND RISK AND THAT CUSTOMER WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO ITS OR ITS AUTHORIZED USERS’ PROPERTY OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF SUCH MATERIAL OR DATA.</li>
<li><u>Product Warranty.</u> Orma Health expressly warrants to Customer that the Devices will materially conform to their published specifications and be reasonably free from defects in material and workmanship, not including reasonable wear and tear or loss, for a period of twelve (12) months commencing on the date of the delivery of any Device to Customer or a Participating Patient. This warranty only applies to Devices received from Orma Health and handled in the manner recommended by Orma Health. </li>
<li><u>Disclaimer.</u> EXCEPT FOR THE EXPRESS WARRANTIES SET FORTH IN THIS SECTION 9, THE SERVICES ARE PROVIDED ON AN AS-IS BASIS.  CUSTOMER’S USE OF THE SOFTWARE AND PURCHASE OF THE SERVICES ARE AT ITS OWN RISK. ORMA HEALTH DOES NOT MAKE, AND HEREBY DISCLAIMS, ANY AND ALL OTHER EXPRESS, STATUTORY AND IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NONINFRINGEMENT AND ACCURACY (OF DATA OR ANY OTHER INFORMATION OR CONTENT), AND ANY WARRANTIES ARISING FROM A COURSE OF DEALING, USAGE, OR TRADE PRACTICE.  ANY WARRANTIES MADE BY ORMA HEALTH ARE FOR THE BENEFIT OF CUSTOMER ONLY AND NOT FOR THE BENEFIT OF ANY THIRD PARTY.  THE SOFTWARE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS. ORMA HEALTH IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGES RESULTING FROM SUCH PROBLEMS. THE ENTIRE RISK ARISING OUT OF USE OR PERFORMANCE OF THE SOFTWARE, INCLUDING WITHOUT LIMITATION ANY INFORMATION, DATA, PRODUCTS, PROCESSES, AND OTHER MATTERS REFERENCED BY THE SERVICES REMAINS WITH THE CUSTOMER. EXCEPT AS EXPRESSLY PROVIDED HEREIN, ORMA HEALTH DOES NOT GUARANTEE CONTINUOUS, ERROR-FREE, VIRUS-FREE OR SECURE OPERATION AND ACCESS TO THE SOFTWARE. </li>
<li><u>Basis of the Bargain.</u> CUSTOMER ACKNOWLEDGES AND AGREES THAT ORMA HEALTH HAS OFFERED ITS SERVICES AND ENTERED INTO THIS AGREEMENT TO WHICH IT IS A PARTY IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN, THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN CUSTOMER AND ORMA HEALTH, AND THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN CUSTOMER AND ORMA HEALTH. CUSTOMER ACKNOWLEDGES AND AGREES THAT ORMA HEALTH WOULD NOT BE ABLE TO PROVIDE THE SERVICES TO CUSTOMER ON AN ECONOMICALLY REASONABLE BASIS WITHOUT THESE LIMITATIONS. </li>
</ol>

<b>10.  INSURANCE AND INDEMNIFICATION.</b>
<ol type='a'>
<li><u>Insurance.</u> Customer shall maintain, at its own expense and in the minimum amounts specified herein, and Orma Health shall be named as an additional insured on each policy: 
  <ol type='i'>
    <li>Professional Errors & Omissions insurance with limits of Five Million Dollars ($5,000,000) per occurrence and Five Million Dollars ($5,000,000) in aggregate.</li>
    <li>Commercial General Liability insurance with limits of Two Million Dollars ($2,000,000) per occurrence and Two Million Dollars ($2,000,000) in aggregate.</li>
  </ol>
</li>
<li><u>Indemnification by Customer.</u> Customer shall indemnify and hold harmless Orma Health and its officers, directors, employees and agents ("Orma Health Indemnified Parties"), from and against any and all damages, liabilities, penalties, interest, fines, losses, costs and expenses (including reasonable attorneys’ fees and expenses) ("Losses"), arising, directly or indirectly, out of or relating to any claim, action or proceeding (a "Claim") brought by a third party based on (i) the improper use or operation of the Services (and any third party software provided to Customer pursuant to this Agreement) by Participating Patients, Customer and/or Authorized Users, including, without limitation, any non-authorized use of Customer’s user logins, except to the extent that any such Loss was due to the gross negligence or willful misconduct of Orma Health; (ii) a breach of the Agreement and/or the TOU by Customer or any of its Authorized Users, (iii) the accuracy, quality, integrity, legality, reliability or appropriateness of Customer Data or any other content or data introduced to any part of the Services by any Authorized User; (iv) violation of any applicable law, rule or regulation by Customer or any of the Authorized Users, (v) the diagnosis and/or treatment of any of Customer’s patients; and/or (vi) the negligent acts or willful misconduct of Customer or its personnel.  Customer will pay all Losses (whether by settlement or award of by a final judicial judgment) incurred by the Orma Health Indemnified Parties from any such Claim.</li>
<li><u>Indemnification by Orma Health.</u> Subject to limitations of liability set forth in this Agreement, Orma Health agrees to defend Customer and its officers, directors, employees and agents (a "Customer Indemnified Party") from and against any Losses resulting from or arising out of a successful claim that the Software infringes or misappropriates the patent, trade secret, trademark, copyright or other Intellectual Property Rights of any third party (an "Infringement Claim"). Orma Health will pay all Losses (whether by settlement or award of by a final judicial judgment) incurred by the Customer Indemnified Parties from any such Claim. In the event of an Infringement Claim, Orma Health may, at its election, and sole expense, (i) modify the Software so that such Software is non-infringing and functionally equivalent; or (ii) obtain the right for Customer and Customer’s patients to continue using the Software at no additional cost to Customer. If none of the foregoing is commercially practicable, Orma Health may immediately terminate this Agreement upon reasonable notice to Customer. </li>
<li><u>Procedure.</u> Each Party shall provide to the other Party prompt notice of any Claim for which they are seeking indemnification. The indemnified Party may have counsel reasonably acceptable to the indemnifying Party observe the proceedings at the indemnified Party’s expense, provided the indemnifying Party retains sole control of the defense of the Claim.  The indemnified Party has the right to approve any settlement that affirmatively places on the indemnified Party an obligation that has a material adverse effect on the indemnified Party other than requiring the indemnified Party to cease using all or a portion of the Services or to pay sums eligible for indemnification under this Agreement. Such approval shall not be unreasonably withheld.</li>
</ol>

<b>11.  LIMITATIONS OF LIABILITY.</b>
<ol type='a'>
<li><u>No Consequential Damages.</u> NEITHER PARTY WILL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY DAMAGES FOR LOST DATA, BUSINESS INTERRUPTION, LOST PROFITS, LOST REVENUE OR LOST BUSINESS ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY SUCH DAMAGES ARISING OUT OF ORMA HEALTH’S PROVISION OR CUSTOMER’S USE OF THE SOFTWARE OR THE RESULTS THEREOF, EVEN IF A PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL ORMA HEALTH BE LIABLE FOR THE COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES.</li>
<li><u>Limits on Liability.</u>  IN NO CASE WILL ORMA HEALTH BE LIABLE FOR ANY AGGREGATE AMOUNT GREATER THAN THE AMOUNTS PAID AND PAYABLE BY CUSTOMER TO ORMA HEALTH UNDER THIS AGREEMENT DURING THE TWELVE (12) MONTH PERIOD PRECEDING THE DATE ON WHICH THE CLAIM FIRST ACCRUED, WITHOUT REGARD TO WHETHER SUCH CLAIM IS BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE.</li>
<li><u>Essential Purpose.</u> CUSTOMER ACKNOWLEDGES THAT THE TERMS IN THIS SECTION 11 (LIMITATION OF LIABILITY) ARE A BARGAINED FOR REASONABLE ALLOCATION OF THE RISK BETWEEN THE PARTIES AND WILL APPLY (A) TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, AND (B) EVEN IF AN EXCLUSIVE OR LIMITED REMEDY STATED HEREIN FAILS OF ITS ESSENTIAL PURPOSE.</li>
<li><u>Exceptions.</u>  The limitations and exclusions of certain damages set forth in Section 11(a) and 11(b) will not apply to injury or damage caused by a Party’s gross negligence or willful misconduct. </li>
<li><u>Limitation of Action.</u>  No action (regardless of form) arising out of this Agreement may be commenced by Customer against Orma Health more than two (2) years after the cause of action arose.</li>
</ol>

<b>12.  MISCELLANEOUS.</b>
<ol type='a'>
<li><u>Subcontractors.</u> Orma Health may use its affiliates or subcontractors to perform its obligations under this Agreement.</li>
<li><u>Notices.</u> Any notices, requests, consents, demands or other communications required or permitted under this Agreement will be in writing and deemed to have been duly given either: (a) when delivered, if delivered by hand, sent by United States registered or certified mail (return receipt requested), delivered personally by commercial courier, or sent by email; or (b) on the second following business day, if sent by United States Express Mail or a nationally recognized commercial overnight courier; and in each case to the parties at the following addresses (or at other addresses as specified by a notice) with applicable postage or delivery charges prepaid. Notices to Orma Health shall be sent to the following address: Orma Health, [llc/inc.], [insert address] or [insert Orma Health email]. Notices to Customer shall be sent to the address specified in the Order Form.</li>
<li><u>Amendment.</u> Except as may otherwise be specified in this Agreement, this Agreement may be modified, changed or amended only by a written amendment mutually agreed to and signed by both Parties. </li>
<li><u>Waiver; Severability.</u> A Party’s right to enforce a provision of this Agreement may only be waived in writing and signed by the Party against which the waiver is to be enforced. Failure to enforce any provision of this Agreement in any one instance will not be construed as a waiver of future performance of that provision, and the Party’s obligations under that provision will continue in full force and effect. The provisions of this Agreement are severable. The invalidity or unenforceability of any term or provision in any jurisdiction will be construed and enforced as if it has been narrowly drawn so as not to be invalid, illegal or unenforceable to the extent possible and will in no way affect the validity or enforceability of any other terms or provisions in that jurisdiction or of this entire Agreement in that jurisdiction.</li>
<li><u>Governing Law.</u> This Agreement, any additional applicable Terms & Conditions, and each Party’s rights and obligations under each will be governed by and construed in accordance with the laws of the State of California without giving effect to conflicts of law principles.</li>
<li><u>Assignment.</u> Neither Party may assign or transfer this Agreement or any additional applicable Terms & Conditions without the prior written consent of the other Party; provided, however, that Orma Health may assign or transfer this Agreement without Customer’s consent to any of Orma Health’s affiliates, subsidiaries, entities controlled by or under common control with Orma Health, or in the event of a merger, change of control or sale of substantially all of its assets.  This Agreement will bind the Parties and their respective successors and permitted assigns and will inure to the benefit of the Parties and their respective permitted successors and assigns.</li>
<li><u>Force Majeure.</u> If any Party is unable to perform any of its obligations under this Agreement (with the exception of payment obligations) because of any cause beyond the reasonable control of and not the fault of the Party invoking this section, including any act of God, fire, casualty, flood, earthquake, war, strike, lockout, epidemic or pandemic, destruction of production facilities, riot, insurrection or material unavailability, and if the non-performing Party has been unable to avoid or overcome its effects through the exercise of commercially reasonable efforts, such non-performing Party will give prompt notice to the other Party, its performance will be excused, and the time for its performance will be extended for the period of delay or inability to perform due to such occurrences.  If performance is extended under this section for more than sixty (60) days, then at any time before reinstatement of the performance, the other Party may terminate this Agreement upon notice to the non-performing Party.</li>
<li><u>Relationship of the Parties.</u> The sole relationship between the Parties is solely that of independent contractors. This Agreement will not create a joint venture, partnership, agency, employment or other relationship between the Parties. </li>
<li><u>Survival.</u> Any term of this Agreement that contemplates performance after termination of this Agreement will survive expiration or termination and continue until fully satisfied.</li>
<li><u>Dispute Resolution.</u> In case of disputes in connection with the negotiation, execution, interpretation, performance or non-performance of this Agreement, the Parties agree to seek non-binding mediation, which shall be conducted remotely by a single mediator selected by the Parties. If the Parties fail to agree on the mediator within thirty (30) days of the date one of them invokes this provision, either Party may apply to the American Arbitration Association to make the appointment. The mediator shall conduct the proceedings pursuant to the rules of the American Arbitration Association, as they exist at the time of the dispute. In the event that any such mediation does not produce a settlement, unless the dispute is otherwise settled, the dispute shall be determined by binding and final arbitration in California, by three (3) arbitrators selected by the Parties (or by the American Arbitration Association if the Parties cannot agree) in accordance with the law of the State of California and the rules of the American Arbitration Association. </li>
<li><u>Entire Agreement.</u> This Agreement, including all applicable Attachments, constitutes the entire agreement between the Parties relating to this subject matter and supersedes all prior or simultaneous understandings, representations, discussions, negotiations, and agreements, whether written or oral. </li>
<li><u>Counterparts.</u> This Agreement may be executed in one or more counterparts.  Each counterpart will be an original, but all such counterparts will constitute a single instrument.</li>
</ol>

Intending to be legally bound, the Parties have caused this Agreement to be executed as of the Effective Date by their respective duly authorized officers.

    </div>
  );
}