import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Table, 
  Header, 
  Loader, 
  Dimmer, 
  Label,
  Grid,
  Form, 
  Button,
  Dropdown,
} from 'semantic-ui-react';
import styled from '@emotion/styled';
import ErrorBoundary from "react-error-boundary";
import { useStitchPufs } from "../StitchPufs";
import { useHistory, Link } from "react-router-dom";
import { useStitchAuth } from "../StitchAuth";
import clientMap from '../clientMap.json';

const stateOptions = _.map(["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", 
  "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", 
  "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", 
  "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", 
  "WV", "WI", "WY"], x => {return {key: x, text: x, value: x};});

String.prototype.toProperCase = function () {
    return this.replace(/[^A-Za-z0-9 ]/g, '').replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

export default function Search(props){

  const { userCustomData, clientName } = useStitchAuth();
  const { searchResults, profile, actions } = useStitchPufs();
  const [ textQuery, setTextQuery ] = useState('');
  const [stateCode, setStateCode] = useState('CA');
  const [inProcess, setInProcess] = useState(true);
  const history = useHistory();

  useEffect(()=>{
    setInProcess(false);
  }, []);

  useEffect(()=>{
    if(userCustomData){
      if (!profile){
        actions.loadProfile(userCustomData.email)
      }
      if (profile && profile.stateCode){
        setStateCode(profile.stateCode);
      }
    }
  }, [userCustomData, profile]);

  const searchTextQuery = () => {
    setInProcess(true);
    actions.searchByName(textQuery, stateCode).then(()=>{
      setInProcess(false);
    });
    history.push({pathname: '/pufs/search-by-name',
                search: '?name=' + textQuery});    
  }

  return <div>
    <Dimmer active={inProcess} inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer>
    <Form size='large'>
      <Form.Group>
        <Form.Input 
          fluid icon='search' 
          iconPosition='left' 
          placeholder='Type the name of the provider e.g. Adrian'
          onChange={(event) => setTextQuery(event.target.value)}
          style={{width: '500px'}}
        />
        <span style={{alignSelf: 'center'}}>
        in
        </span>
        <Form.Field>
          <Dropdown
            placeholder='.'
            fluid
            selection
            options={stateOptions}
            value={stateCode}
            onChange={(e, v) => {
              setStateCode(v.value);
              actions.saveProfile(userCustomData.email, {stateCode: v.value});
            }}
          />
        </Form.Field>
        <Button 
          fluid 
          onClick={()=>searchTextQuery()}
          style={{width: '100px'}}>
          Search
        </Button>
      </Form.Group>
    </Form>
      {searchResults.length >= 50 ?
          <span>Showing the first 50 results of many:</span>: 
          <span>{searchResults.length} results found:</span>}
    {searchResults.length > 0 &&
    <Table size='small' basic='very'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            Name
          </Table.HeaderCell>
          <Table.HeaderCell>
            Provider Type
          </Table.HeaderCell>
          <Table.HeaderCell>
            Address
          </Table.HeaderCell>
          <Table.HeaderCell>
            # FFS Members
          </Table.HeaderCell>
          <Table.HeaderCell>
            Avg. HCC Risk Score
          </Table.HeaderCell>                    
          <Table.HeaderCell>
            Select
          </Table.HeaderCell>                              
        </Table.Row>
      </Table.Header>
      <Table.Body>
      {_.map(searchResults, d => (
        <Table.Row key={d.npi}>
          <Table.Cell>
            <Link to={{pathname: '/pufs/find-by-npi', search: '?npi='+d.npi, }}>
              {d.name.toProperCase()}<br/>
              NPI: {d.npi}  
            </Link>
          </Table.Cell>
          <Table.Cell>
            {d.provider_type}
          </Table.Cell>
          <Table.Cell>
            <a href={"https://www.google.com/maps/place/" + 
                d.nppes_provider_street1 + ", " + 
                d.nppes_provider_city + ", " + 
                d.nppes_provider_state + " " + d.nppes_provider_zip.substring(0,5)} target="_blank">
                {d.nppes_provider_street1}<br/> 
                {d.nppes_provider_city + ", " + 
                d.nppes_provider_state + " " + d.nppes_provider_zip.substring(0,5)}
            </a>
          </Table.Cell>
          <Table.Cell>
            {d.total_unique_benes['2017']?d.total_unique_benes['2017']:'.'}
          </Table.Cell>
          <Table.Cell>
            {d.beneficiary_average_risk_score['2017']?d.beneficiary_average_risk_score['2017']:'.'}
          </Table.Cell>
          <Table.Cell>
            {(profile && _.map(profile.selectedProviders, x=>x.npi).indexOf(d.npi) >= 0)?
              <Button basic size='small' color='red' onClick={()=>{
                setInProcess(true);
                actions.saveProfile(userCustomData.email, 
                    {...profile, 
                      selectedProviders: _.filter(profile.selectedProviders, x => x.npi !== d.npi)})
                  .then(()=>setInProcess(false));
              }}>
                Remove
              </Button>:
              <Button basic size='small' color='blue' onClick={()=>{
                setInProcess(true);
                actions.saveProfile(userCustomData.email, 
                    {...profile, 
                      selectedProviders: [...profile.selectedProviders, {npi: d.npi, name: d.name}]})
                  .then(()=>setInProcess(false));
              }}>
                Add to Group
              </Button>
            }
          </Table.Cell>
        </Table.Row>
        ))}
      </Table.Body>
    </Table>}
  </div>;

}