import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Table, 
  Header, 
  Loader, 
  Dimmer, 
  Divider,
  Label,
  Grid,
  Form, 
  Button,
  Dropdown,
} from 'semantic-ui-react';
import styled from '@emotion/styled';
import ErrorBoundary from "react-error-boundary";
import { useStitchPufs } from "../StitchPufs";
import { useHistory, useLocation } from "react-router-dom";
import { useStitchAuth } from "../StitchAuth";
import clientMap from '../clientMap.json';
import {
  LineChart, 
  Line, 
  ScatterChart,
  Scatter,
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip,
  ReferenceLine,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';

const RedFont = styled.span`
  color: #dc3545;
`
const GreenFont = styled.span`
  color: #20c997;
`
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Find(props){

  const { userCustomData, clientName } = useStitchAuth();
  const { npiResult, profile, actions } = useStitchPufs();
  const [ npi, setNpi ] = useState('');
  const [inProcess, setInProcess] = useState(true);
  const history = useHistory();
  let query = useQuery();

  useEffect(()=>{
    if (userCustomData){
      let queryParam = query.get('npi');
      if (queryParam){
        setNpi(queryParam);
        actions.findNpi(queryParam);
      }
      if (npiResult && !queryParam){
        setNpi(npiResult.doctor.npi);
      }
      if (!profile){
        actions.loadProfile(userCustomData.email)
      }
      setInProcess(false);
    }
  }, [userCustomData, profile]);

  const findNpi = () => {
    setInProcess(true);
    actions.findNpi(npi).then(()=>setInProcess(false));
    history.push({pathname: '/pufs/find-by-npi',
                search: '?npi=' + npi});
  }

  return <div>
    <Dimmer active={inProcess} inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer>
    <Form size='large'>
      <Form.Group>
        <Form.Input 
          fluid icon='search' 
          iconPosition='left' 
          placeholder='Type the exact NPI'
          value={npi}
          onChange={(event)=>setNpi(event.target.value)}
          style={{width: '500px'}}
        />
        <Button 
          fluid 
          onClick={()=>findNpi()}
          style={{width: '100px'}}>
          Find
        </Button>
      </Form.Group>
    </Form>
    <Divider hidden />
    {(npiResult && npiResult.doctor) && 
      <Grid style={{paddingRight: '2em'}}>
        <Grid.Row textAlign="center">
          <Grid.Column>
            <Header as='h2'>
              {npiResult.doctor.name.toProperCase()}, {npiResult.doctor.nppes_credentials}
            </Header>
            &nbsp;&nbsp;
            {(profile && _.map(profile.selectedProviders, x=>x.npi).indexOf(npiResult.doctor.npi) >= 0)?
              <Button basic size='small' color='red' onClick={()=>{
                setInProcess(true);
                actions.saveProfile(userCustomData.email, 
                    {...profile, 
                      selectedProviders: _.filter(profile.selectedProviders, x => x.npi !== npiResult.doctor.npi)})
                  .then(()=>setInProcess(false));
              }}>
                Remove
              </Button>:
              <Button basic size='small' color='blue' onClick={()=>{
                setInProcess(true);
                actions.saveProfile(userCustomData.email, 
                    {...profile, 
                      selectedProviders: [...profile.selectedProviders, {npi: npiResult.doctor.npi, name: npiResult.doctor.name}]})
                  .then(()=>setInProcess(false));
              }}>
                Add to Group
              </Button>
            }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row textAlign="center" columns="four">
          <Grid.Column>
            <Header as='h4' style={{lineHeight: '1.7em'}}>
              {npiResult.doctor.name.toProperCase()}<br/>
              {npiResult.doctor.npi}
              <Header.Subheader>
              Name & NPI
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as='h4' style={{lineHeight: '1.7em'}}>
              {npiResult.doctor.provider_type}<br/>
              {npiResult.doctor.nppes_credentials}
              <Header.Subheader>
              Provider Type & Credentials
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as='h4' style={{lineHeight: '1.7em'}}>
              {npiResult.doctor.nppes_provider_street1} {npiResult.doctor.nppes_provider_street2}<br/>
              {npiResult.doctor.nppes_provider_city}, {npiResult.doctor.nppes_provider_state} {npiResult.doctor.nppes_provider_zip.substring(0,5)}
              <Header.Subheader>
              Address
              </Header.Subheader>
            </Header>
          </Grid.Column>    
          <Grid.Column>
            <Header as='h4' style={{lineHeight: '1.7em'}}>
              {npiResult.doctor.refYear}
              <Header.Subheader>
              Data Reference Year
              </Header.Subheader>
            </Header>
          </Grid.Column>                        
        </Grid.Row>
        <Grid.Row textAlign='center' columns='three'>
          <Grid.Column>
            <Header as='h1' style={{lineHeight: '1.7em'}}>
              {numberWithCommas(npiResult.doctor.total_unique_benes[npiResult.doctor.refYear])}
              &nbsp;
                <span style={{fontSize: '0.6em'}}>
                  {(npiResult.doctor.total_unique_benes.yoy > 1)?
                    (<GreenFont>+{Math.round(npiResult.doctor.total_unique_benes.yoy*100-100)}%</GreenFont>):
                    (<RedFont>{Math.round(npiResult.doctor.total_unique_benes.yoy*100-100)}%</RedFont>)}
                  &nbsp;YoY
                </span>
              <Header.Subheader>
                # of Unique Beneficiaries <br/>
                <span  style={{fontSize: '0.7em', lineHeight: '1.2em'}}>
                  {npiResult.county && <span>{npiResult.county.name.split("::")[1] + " County (YoY): "}</span>}
                  {(npiResult.county && npiResult.county.total_unique_benes.yoy > 1) &&
                    <GreenFont>+{Math.round(npiResult.county.total_unique_benes.yoy*100-100)}%</GreenFont>}
                  {(npiResult.county && npiResult.county.total_unique_benes.yoy <= 1) &&
                    <RedFont>{Math.round(npiResult.county.total_unique_benes.yoy*100-100)}%</RedFont>}                  
                  <br/>
                  {npiResult.state && <span>{npiResult.state.name + " (YoY): "}</span>}
                  {(npiResult.state && npiResult.state.total_unique_benes.yoy > 1) &&
                    <GreenFont>+{Math.round(npiResult.state.total_unique_benes.yoy*100-100)}%</GreenFont>}
                  {(npiResult.state && npiResult.state.total_unique_benes.yoy <= 1) &&
                    <RedFont>{Math.round(npiResult.state.total_unique_benes.yoy*100-100)}%</RedFont>}   
                </span>
              </Header.Subheader>
            </Header>  
          </Grid.Column>
          <Grid.Column>
            <Header as='h1' style={{lineHeight: '1.7em'}}>
              {npiResult.doctor.beneficiary_average_risk_score[npiResult.doctor.refYear]}
              &nbsp;
                <span style={{fontSize: '0.6em'}}>
                  {(npiResult.doctor.beneficiary_average_risk_score.yoy > 1)?
                    (<GreenFont>+{Math.round(npiResult.doctor.beneficiary_average_risk_score.yoy*100-100)}%</GreenFont>):
                    (<RedFont>{Math.round(npiResult.doctor.beneficiary_average_risk_score.yoy*100-100)}%</RedFont>)}
                  &nbsp;YoY
                </span>
              <Header.Subheader>
                Avg. HCC Risk Score<br/>
                <span  style={{fontSize: '0.7em', lineHeight: '1.2em'}}>
                  {npiResult.county && <span>{npiResult.county.name.split("::")[1] + " County (YoY): "}</span>}
                  {(npiResult.county && npiResult.county.beneficiary_average_risk_score.yoy > 1) &&
                    <GreenFont>+{Math.round(npiResult.county.beneficiary_average_risk_score.yoy*100-100)}%</GreenFont>}
                  {(npiResult.county && npiResult.county.beneficiary_average_risk_score.yoy <= 1) &&
                    <RedFont>{Math.round(npiResult.county.beneficiary_average_risk_score.yoy*100-100)}%</RedFont>}                  
                  <br/>
                  {npiResult.state && <span>{npiResult.state.name + " (YoY): "}</span>}
                  {(npiResult.state && npiResult.state.beneficiary_average_risk_score.yoy > 1) &&
                    <GreenFont>+{Math.round(npiResult.state.beneficiary_average_risk_score.yoy*100-100)}%</GreenFont>}
                  {(npiResult.state && npiResult.state.beneficiary_average_risk_score.yoy <= 1) &&
                    <RedFont>{Math.round(npiResult.state.beneficiary_average_risk_score.yoy*100-100)}%</RedFont>}   
                </span>
              </Header.Subheader>
            </Header>  
          </Grid.Column>
          <Grid.Column>
            <Header as='h1' style={{lineHeight: '1.7em'}}>
              ${numberWithCommas(npiResult.doctor.allowed_amt_per_bene[npiResult.doctor.refYear])}
              &nbsp;
                <span style={{fontSize: '0.6em'}}>
                  {(npiResult.doctor.allowed_amt_per_bene.yoy > 1)?
                    (<GreenFont>+{Math.round(npiResult.doctor.allowed_amt_per_bene.yoy*100-100)}%</GreenFont>):
                    (<RedFont>{Math.round(npiResult.doctor.allowed_amt_per_bene.yoy*100-100)}%</RedFont>)}
                  &nbsp;YoY
                </span>
              <Header.Subheader>
                Medicare Allowed Amounts<br/>per Beneficiary (USD)
              </Header.Subheader>
            </Header>  
          </Grid.Column>
        </Grid.Row>
        <Grid.Row textAlign="center" columns="three">
          {npiResult.doctor && 
            <Grid.Column>
              <Header as='h4' style={{fontWeight: 300, color: '#869AB8'}}>
                # of Unique Beneficiaries vs. Years
              </Header>
             <ResponsiveContainer width='100%' height={250}>
              <LineChart
                data={npiResult.doctor.plotBenes}
                margin={{
                  top: 0, right: 10, left: 10, bottom: 20,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey='year'/>
                <YAxis type='number'
                  domain={[dataMin => Math.round(Math.max(dataMin-10,0)), dataMax => Math.round(dataMax+10)]}
                />
                <Line type="linear" dataKey="value" stroke="#3c72ca" strokeWidth={2} 
                  dot={{ strokeWidth: 1 }} />
                <Tooltip/>
              </LineChart>
            </ResponsiveContainer>
          </Grid.Column>}
          {npiResult.doctor && 
            <Grid.Column>
              <Header as='h4' style={{fontWeight: 300, color: '#869AB8'}}>
                Avg. HCC Risk Scores vs. Years
              </Header>
             <ResponsiveContainer width='100%' height={250}>
              <LineChart
                data={npiResult.doctor.plotRisk}
                margin={{
                  top: 0, right: 10, left: 10, bottom: 20,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey='year'/>
                <YAxis type='number'
                  domain={[dataMin => Math.floor((dataMin-0.1)*100)/100, dataMax => Math.ceil((dataMax+0.1)*100)/100]}
                />
                <Line type="linear" dataKey="value" stroke="#3c72ca" strokeWidth={2} 
                  dot={{ strokeWidth: 1 }} />
                <Tooltip/>
              </LineChart>
            </ResponsiveContainer>
          </Grid.Column>}
          {npiResult.doctor && 
            <Grid.Column>
              <Header as='h4' style={{fontWeight: 300, color: '#869AB8'}}>
                Medicare Allowed Amt./Bene. vs. Years
              </Header>
             <ResponsiveContainer width='100%' height={250}>
              <LineChart
                data={npiResult.doctor.plotAmount}
                margin={{
                  top: 0, right: 10, left: 10, bottom: 20,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey='year'/>
                <YAxis type='number'
                  domain={[dataMin => Math.max(Math.round(dataMin - 10,0),0), dataMax => Math.round(dataMax + 10)]}
                />
                <Line type="linear" dataKey="value" stroke="#3c72ca" strokeWidth={2} 
                  dot={{ strokeWidth: 1 }} />
                <Tooltip/>
              </LineChart>
            </ResponsiveContainer>
          </Grid.Column>}
        </Grid.Row>
        <Grid.Row textAlign="center" columns="two">
          {npiResult.doctor && 
            <Grid.Column>
              <Header as='h4' style={{fontWeight: 300, color: '#869AB8'}}>
                Gender Distributions vs. Years
              </Header>
              <ResponsiveContainer width='100%' height={250}>
                <BarChart
                  data={npiResult.doctor.plotGender}
                  margin={{
                    top: 0, right: 10, left: 10, bottom: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey='year'/>
                  <YAxis/>
                  <Tooltip/>
                  <Bar dataKey="male" stackId="a" fill="#3c72ca" />
                  <Bar dataKey="female" stackId="a" fill="#a8bdce" />   
                  <Legend layout='vertical' verticalAlign="middle" align="right" wrapperStyle={{
                      paddingLeft: "10px"
                  }}/>               
                </BarChart>
              </ResponsiveContainer>
            </Grid.Column>
          }
          {npiResult.doctor && 
            <Grid.Column>
              <Header as='h4' style={{fontWeight: 300, color: '#869AB8'}}>
                Ethnicity Distributions vs. Years
              </Header>
              <ResponsiveContainer width='100%' height={250}>
                <BarChart
                  data={npiResult.doctor.plotRace}
                  margin={{
                    top: 0, right: 10, left: 10, bottom: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey='year'/>
                  <YAxis/>
                  <Tooltip/>
                  <Bar dataKey="white" stackId="a" fill="#3c72ca" />
                  <Bar dataKey="black" stackId="a" fill="#a8bdce" />
                  <Bar dataKey="asianPacific" stackId="a" fill="#20c997" />
                  <Bar dataKey="hispanic" stackId="a" fill="#6C8AEC" />
                  <Bar dataKey="americanIndian" stackId="a" fill="#7C69EF" />                                                         
                  <Bar dataKey="others" stackId="a" fill="#506690" />
                  <Legend layout='vertical' verticalAlign="middle" align="right" wrapperStyle={{
                      paddingLeft: "10px"
                  }}/>               
                </BarChart>
              </ResponsiveContainer>
            </Grid.Column>
          }
        </Grid.Row>
        <Grid.Row textAlign="center">
          {npiResult.doctor && 
          <Grid.Column>
            <Header as='h4' style={{fontWeight: 300, color: '#869AB8'}}>
              Percentages of Beneficiaries with Chronic Conditions in {npiResult.doctor.refYear}
            </Header>
            <ResponsiveContainer width='100%' height={600}>
              <BarChart
                data={npiResult.doctor.barChart}
                margin={{
                  top: 0, right: 80, left: 120, bottom: 20,
                }}
                layout="vertical"
              >
                <XAxis type="number" unit="%"/>
                <YAxis dataKey="condition" type="category" tick={{fontSize: 10}}/>
                <Tooltip />
                <Legend layout="vertical" verticalAlign="middle" align="right" />
                <Bar dataKey="Provider" fill="#3c72ca" unit="%"/>
                <Bar dataKey="County" fill="#a8bdce" unit="%"/>
                <Bar dataKey="State" fill="#cbd7e1" unit="%"/>                                
              </BarChart>
            </ResponsiveContainer>
          </Grid.Column>}
        </Grid.Row>                
      </Grid>
    }
    {!npiResult &&
      <Grid>
        <Grid.Row textAlign="center">
          <Grid.Column>
            <span style={{color: '#869AB8', fontWeight: 300}}>
              No result.
            </span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    }
  </div>;

}