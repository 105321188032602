import React, { useEffect, useState } from "react";
import { useStitchAuth } from "../StitchAuth";
import { Input, Search, Icon } from 'semantic-ui-react';
import { 
  Link,
  NavLink,
  useHistory, 
  useLocation,
} from "react-router-dom";
import _ from 'lodash';
import { members } from "./../../stitch";
import clientMap from '../clientMap.json';
import styled from '@emotion/styled';

const UnderLine = styled.span`
  border-bottom: 1px solid currentColor;
  display: inline-block;
  line-height: 1.5;
  font-weight: 400;
`

export default function AppBar(props){

  const { currentUser, clientName, userCustomData, actions} = useStitchAuth();
  const [logo, setLogo] = useState(clientName + "_bw.png");
  let history = useHistory();
  let location = useLocation();
  const [menu, setMenu] = useState('Home');
  const locMap = {pufs: 'PUFs',
              members: 'Members',
              performance: 'Performance'}
  const activeStyle = {
      borderBottom: '1px solid currentColor',
  display: 'inline-block',
  lineHeight: 1.5,
  fontWeight: 400,
  };


  useEffect(() => {
    setLogo(clientName + "_bw.png");
  }, [clientName]);

  useEffect(()=> {
    if (userCustomData && !userCustomData.customer_agreement_consent) {
      history.replace('/agreement');
    }
  }, [userCustomData]);

  useEffect(() => {
    const loc = location.pathname.split('/')[1];
    if (loc){
      setMenu(locMap[loc] || 'PUFs');
    }
  }, [location]);

  const logout = (e) => {
    e.preventDefault();
    actions.handleLogout();
  }

  if (userCustomData && !userCustomData.customer_agreement_consent){
    return (
      <div className='appbar box'>
        <Link to="/pufs/search-by-name">
          <img alt="logo" src='/logos/orma_bw.png' style={{ height: '25px', float: 'left', paddingLeft: '1em'}} />
        </Link>
        <ul style={{float: 'right', listStyle: 'none', margin: 0, fontWeight: 300, fontSize: '1em'}}>
          <li style={{float: 'left', marginLeft: '1em'}}>
            <a href="#" onClick={logout} style={{color: '#fff'}}>
              Logout
            </a>
          </li>
        </ul>
      </div>
    );
  }else{
    return (
      <div className='appbar box'>
        <a href="/pufs/search-by-name">
          <img alt="logo" src='/logos/orma_bw.png' style={{ height: '25px', float: 'left', paddingLeft: '1em'}} />
        </a>
        <ul style={{float: 'right', listStyle: 'none', margin: 0, fontWeight: 300, fontSize: '1em'}}>
          <li style={{float: 'left', marginLeft: '1em'}}>
            <a href="https://app.arqinsights.com/login" style={{color: '#fff'}} target="_blank">
              <Icon name="play circle" />
              ARQ
            </a>
          </li>
          <li style={{float: 'left', marginLeft: '1em'}}>
            <a href="https://rpm2.ormahealth.com/login" style={{color: '#fff'}} target="_blank">
              <Icon name="play circle" />
              RPM
            </a>
          </li>
          <li style={{float: 'left', marginLeft: '1em'}}>
            <a href="#" onClick={logout} style={{color: '#fff'}}>
              Logout
            </a>
          </li>
        </ul>
      </div>
    );
  }
}
