import React from "react";
import PropTypes from "prop-types";
import {
  hasLoggedInUser,
  logoutCurrentUser,
  getCurrentUser,
  getCustomData,
  signupUser,
  loginUser,
  sendResetPasswordEmail,
  resetPassword,
  confirmEmail,
} from "./../stitch/authentication";
import { users, groups } from "./../stitch";
import { getDefaultClientName } from './Common/whitelabel';


// Create a React Context that lets us expose and access auth state
// without passing props through many levels of the component tree
const StitchAuthContext = React.createContext();

// Create a React Hook that lets us get data from our auth context
export function useStitchAuth() {
  const context = React.useContext(StitchAuthContext);
  if (!context) {
    throw new Error(`useStitchAuth must be used within a StitchAuthProvider`);
  }
  return context;
}

// Create a component that controls auth state and exposes it via
// the React Context we created.
export function StitchAuthProvider(props) {

  const [authState, setAuthState] = React.useState({
    isLoggedIn: hasLoggedInUser(),
    currentUser: getCurrentUser(),
    userCustomData: null,
    clientName: getDefaultClientName(),
  });

  // Authentication Actions
  const handleLogout = async () => {
    const { isLoggedIn } = authState;
    if (isLoggedIn) {
      await logoutCurrentUser();
      setAuthState({
        ...authState,
        isLoggedIn: false,
        currentUser: null,
        userCustomData: null,
      });
    } else {
      console.log(`can't handleLogout when no user is logged in`);
    }
  };

  const handleSignup = async (email, password) => {
    const signupStatus = await signupUser(email, password);
  }

  const handleUserSignup = async (email, password, name, namePublic, myPassword) => {
    const check = await loginUser(authState.userCustomData.email, myPassword);
    // TODO: clientName can be inserted at this point
    if(check){
      const signupStatus = await signupUser(email, password);
      const loggedInUser = await loginUser(email, password);
      const customData = {name: name, 
                  namePublic: namePublic,
                  timeZone: authState.userCustomData.timeZone,
                  role: 'user',
                  group_id: authState.userCustomData.group_id,
                  id: loggedInUser.id,
                  email: email};
      users.insertOne(customData);
      await loginUser(authState.userCustomData.email, myPassword);
    }
  }

  const handleConfirmEmail = async (token, tokenId) => {
    confirmEmail(token, tokenId);
  }

  const handleEmailPasswordLogin = async (email, password) => {
    const loggedInUser = await loginUser(email, password);
    //console.log(loggedInUser);
    setAuthState({
        ...authState,
        isLoggedIn: true,
        currentUser: loggedInUser,
    });

  }

  const handleResetPasswordSend = async (email) => {
    sendResetPasswordEmail(email);
  }

  const handleResetPassword = async (token, tokenId, newPassword) => {
    resetPassword(token, tokenId, newPassword);
  }

  const handleRefreshCustomData = async () => {
    getCustomData().then((customData)=>{
      setAuthState({
        ...authState,
        userCustomData: customData,
        clientName: (customData.clientName?customData.clientName:'orma'),
      });
    });
  }

  const updateCustomData = async (customData) => {
    setAuthState({
      ...authState,
      userCustomData: {
        ...authState.userCustomData,
        ...customData,
      },
      clientName: (customData.clientName?customData.clientName:'orma'),
    });
    users.findOneAndUpdate({id: authState.currentUser.id}, {'$set': customData});
  }

  const updateClinicalRules = async (key, value) => {
    groups.findOneAndUpdate({group_id: authState.userCustomData.group_id},
      {'$set': {['clinical_rules.'+key]: value}});
    setAuthState({
      ...authState,
      userCustomData: {
        ...authState.userCustomData,
        clinical_rules: {
          ...authState.userCustomData.clinical_rules,
          [key]: value
        }
      }
    });
  }

  const updateBillingConfig = async (billingConfig) => {
    groups.findOneAndUpdate({group_id: authState.userCustomData.group_id},
      {'$set': {'billingConfig': {...billingConfig}}});
    setAuthState({
      ...authState,
      userCustomData: {
        ...authState.userCustomData,
        billingConfig: {...billingConfig},
      }
    });
  }

  const updateConsents = async (consents) => {
    groups.findOneAndUpdate({group_id: authState.userCustomData.group_id},
      {'$set': consents});
    setAuthState({
      ...authState,
      userCustomData: {
        ...authState.userCustomData,
        ...consents
      }
    });
  }
  
  const updateAgreement = async (agreement) => {
    groups.findOneAndUpdate({group_id: authState.userCustomData.group_id},
      {'$set': agreement});

    users.findOneAndUpdate({id: authState.currentUser.id}, 
      {'$set': {name: agreement.agreement.contactName,
        namePublic: agreement.agreement.contactName}});

    setAuthState({
      ...authState,
      userCustomData: {
        ...authState.userCustomData,
        ...agreement,
        name: agreement.agreement.contactName,
        namePublic: agreement.agreement.contactName,
      }
    });

  }

  const getAllAccounts = async () => {
    const accounts = await users.find({}).asArray();
    return accounts;
  }

  // We useMemo to improve performance by eliminating some re-renders
  const authInfo = React.useMemo(
    () => {
      const { isLoggedIn, currentUser, userCustomData, clientName } = authState;
      const value = {
        isLoggedIn,
        currentUser,
        userCustomData,
        clientName,
        actions: { 
                  handleLogout,
                  handleSignup,
                  handleConfirmEmail,
                  handleEmailPasswordLogin,
                  handleResetPasswordSend,
                  handleResetPassword,
                  handleRefreshCustomData,
                  updateCustomData,
                  updateClinicalRules,
                  updateBillingConfig,
                  updateConsents,
                  updateAgreement,
                  getAllAccounts,
                  handleUserSignup,
                },
      };
      return value;
    },
    [authState, authState.currentUser, authState.clientName, ],
  );
  return (
    <StitchAuthContext.Provider value={authInfo}>
      {props.children}
    </StitchAuthContext.Provider>
  );
}
StitchAuthProvider.propTypes = {
  children: PropTypes.element,
};
