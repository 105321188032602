import React from "react";
import AppBar from "../Common/AppBar";
import "../Common/Layout.css";
import AgreementForm from './AgreementForm';
import { Header } from 'semantic-ui-react'

export default function Agreement(props){

  return (
      <div className='app-layout'>
        <AppBar />
        <div className='channels box'>
          <Header as='h4' style={{paddingLeft: '1em', color: '#fff'}}>
            Customer Agreement
          </Header>
        </div>
        <div className='header-panel box'>
          <Header as='h4'>
            Thanks for choosing Orma Health!
          </Header>
        </div>
        <AgreementForm disableInput={false}/>
      </div>
    );
}
